import React, { useEffect } from "react";
import { ThemeProvider } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

/* PAGES */
import Homepage from "../pages/homepage";
import NursingAssistantTrainingProgram from "../pages/programs/nursing-assistant-program";
import LicensedVocationalNurseProgram from "../pages/programs/vocational-nurse-program";
import MedicalOfficeAdministrativeProgram from "../pages/programs/medical-office-administrative-assistant";
import MedicalAssistantProgram from "../pages/programs/medical-assistant-program";
import AboutUs from "../pages/about-us";
import Approvals from "../pages/about-us/approvals";
import AdministrationStaff from "../pages/about-us/administration-staff";
import Careers from "../pages/about-us/careers";
import RequestInformation from "../pages/get-started/request-information";
import ApplyOnline from "../pages/get-started/apply-online";
import FAQ from "../pages/get-started/faq";
import ContactUs from "../pages/contact-us";
import TranscriptRequestForm from "../pages/contact-us/transcript-request-form";
import Blogs from "../pages/blogs/";
import BlogDetails from "../pages/blogs/blog-details";
import CreditTransferPolicy from "../pages/misc-pages/credit-transfer-policy";
import PaymentPlanOptions from "../pages/misc-pages/payment-plan-options";
import PrivacyPolicy from "../pages/misc-pages/privacy-policy";
import AdvisingCounseling from "../pages/misc-pages/advising-counseling";
import LibraryServices from "../pages/misc-pages/library-services";
import StateCompetencyExam from "../pages/misc-pages/state-exam-preparation";
import Testimonials from "../pages/testimonials";

/* LAYOUT */
import Layout from "../layout/index";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const AppRoutes = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <div className="app">
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route
                path="/nursing-assistant-program"
                element={<NursingAssistantTrainingProgram />}
              />
              <Route
                path="/vocational-nursing-program"
                element={<LicensedVocationalNurseProgram />}
              />
              <Route
                path="/medical-office-administrative-assistant-program"
                element={<MedicalOfficeAdministrativeProgram />}
              />
              <Route
                path="/medical-assistant-program"
                element={<MedicalAssistantProgram />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/approvals" element={<Approvals />} />
              <Route
                path="/administration-and-staff"
                element={<AdministrationStaff />}
              />
              <Route path="/employment-opportunity" element={<Careers />} />
              <Route
                path="/request-information"
                element={<RequestInformation />}
              />
              <Route path="/apply-online" element={<ApplyOnline />} />
              <Route path="/faqs" element={<FAQ />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/transcript-request-form"
                element={<TranscriptRequestForm />}
              />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog-detail/:id" element={<BlogDetails />} />
              <Route
                path="/credit-transfer-policy"
                element={<CreditTransferPolicy />}
              />
              <Route
                path="/payment-plan-options"
                element={<PaymentPlanOptions />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/advising-counseling"
                element={<AdvisingCounseling />}
              />
              <Route path="/library-services" element={<LibraryServices />} />
              <Route path="/state-exam-preparation" element={<StateCompetencyExam />} />
              <Route path="/testimonials" element={<Testimonials />} />
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default AppRoutes;