import React from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../../config";

/* REACT BOOTSTRAP COMPONENTS */
import { Accordion } from "react-bootstrap";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const FAQ = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Frequently Asked Questions | Clement Career College</title>
        <meta
          name="description"
          content="Check out some of the most frequently asked questions and their answers. Learn about many general FAQs related to nurse assistant right here."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/contact-us"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="program"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Frequently Asked Questions"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">FREQUENTLY ASKED QUESTIONS</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="program-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <p className="big-text">
                  We are excited about your interest in Clement Career College.
                  We put together a list of questions we get from potential
                  students about our programs. If you have a question not
                  covered on the list, please reach out and{" "}
                  <Link
                    to="/contact-us"
                    style={{ color: "#7ED644", textDecoration: "none" }}
                  >
                    contact our admissions office
                  </Link>
                  .
                </p>
              </div>
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <Accordion className="questions">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there different schedules offered for the nursing
                      assistant training program?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">Yes, we offer two sections:</p>
                      <ul className="list">
                        <li>
                          <b>Full Time Morning Section:</b> Monday to Friday
                          8:00am to 4:30PM
                        </li>
                        <li>
                          <b>Part Time Evening Section: </b> Monday to Thursday
                          4:00PM to 8:00PM
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What is the duration of the nursing assistant training
                      program ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        <b>Full Time:</b> 6 weeks
                      </p>
                      <p className="text">
                        <b>Part Time:</b> 11 weeks
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What is the total cost for the nursing assistant training
                      program ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        The total cost for the program is $1840.00
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What payment methods are accepted ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Our payment plan option accept payment by credit card or
                        debit card, money order, cash, manager’s cashier’s
                        cheque. <b>No personal Cheque.</b>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      When can I take the state exam ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Graduates of our program are scheduled for the next
                        available date after graduation. The available dates and
                        locations for national exams can be found at this{" "}
                        <a
                          href="http://regionaltestingcenter.org/cna.php"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#7ED644", textDecoration: "none" }}
                        >
                          link.
                        </a>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How long will it take to get my license after I pass my
                      exam ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        The board will send you your license within 8 weeks.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Where will the clinical practice take place ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Clinical practice will take place at approved clinical
                        sites that are in partnership with Clement Career
                        College.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Will the clinical practice schedule be the same as the
                      theory schedule ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Clinical Hours may differ slightly based on the
                        facility’s hours of operation.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Is Clement Career College an accredited school ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Yes, Clement Career College is accredited by the BPPE
                        and BVNPT. Our Nursing Assistant Program is approved by
                        the California Department of Public Health (CDPH).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Do you offer financial aid ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        No, Clement Career College does not offer Federal or
                        State Financial aid program.
                      </p>
                      <p className="text">
                        A Payment plan option is available for the Nursing
                        Assistant Program.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      Will there be jobs available once I finish my program ?
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p className="text">
                        Clement Career College do not guarantee the job
                        placement but we help to place our graduates.
                      </p>
                      <p className="text mt-1">
                        According to the Bureau of Labor Statistics,
                        Occupational Outlook Handbook, Employment of healthcare
                        occupations is projected to grow 18 percent from 2016 to
                        2026, much faster than the average for all occupations,
                        adding about 2.4 million new jobs. Healthcare
                        occupations are projected to add more jobs than any of
                        the other occupational groups. This projected growth is
                        mainly due to an aging population, leading to greater
                        demand for healthcare services.
                      </p>
                      <p className="text mt-1">
                        Healthcare support occupations (such as home health
                        aides, occupational therapy assistants, and medical
                        transcribers) had a median annual wage of $28,710 in May
                        2017, lower than the median annual wage for all
                        occupations in the economy.
                      </p>
                      <a
                        href="https://www.bls.gov/oes/current/oes311131.htm"
                        target="_blank"
                        rel="noreferrer"
                        className="mt-1 d-block"
                        style={{
                          color: "#7ED644",
                          textDecoration: "none",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Bureau of Labor Statistics
                      </a>
                      <p className="text mt-1">
                        Employment estimate and mean wage estimates for this
                        occupation:
                      </p>
                      <table className="table border mt-2">
                        <thead className="table-head">
                          <th>
                            Employment{" "}
                            <a
                              href="https://www.bls.gov/oes/current/oes311131.htm#(1)"
                              style={{
                                textDecoration: "none",
                                color: "#7ED644",
                              }}
                            >
                              (1)
                            </a>
                          </th>
                          <th>
                            Employment RSE{" "}
                            <a
                              href="https://www.bls.gov/oes/current/oes311131.htm#(3)"
                              style={{
                                textDecoration: "none",
                                color: "#7ED644",
                              }}
                            >
                              (3)
                            </a>
                          </th>
                          <th>Mean Hourly Wage</th>
                          <th>
                            Mean Annual Wage{" "}
                            <a
                              href="https://www.bls.gov/oes/current/oes311131.htm#(2)"
                              style={{
                                textDecoration: "none",
                                color: "#7ED644",
                              }}
                            >
                              (2)
                            </a>
                          </th>
                          <th>
                            Wage RSE{" "}
                            <a
                              href="https://www.bls.gov/oes/current/oes311131.htm#(3)"
                              style={{
                                textDecoration: "none",
                                color: "#7ED644",
                              }}
                            >
                              (3)
                            </a>
                          </th>
                        </thead>
                        <tbody className="table-body">
                          <tr>
                            <td>1,450,960</td>
                            <td>0.5%</td>
                            <td>$14.22</td>
                            <td>$29,580</td>
                            <td>0.2%</td>
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default FAQ;
