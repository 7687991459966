import request from "./request";

//GET INSTAGRAM POSTS
export const getInstagramPosts = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/instagramPosts`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Landing Page Form API
export const landingPageForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/landingPageForm`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const contactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/contactUs`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Program Information API
export const programInformation = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/programInformation`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


// Book An Appointment API
export const bookAnAppointment = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/bookAnAppointment`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Apply Online Form API
export const applyOnline = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/applyOnline`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Transcript Form API
export const transcriptForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/transcript`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs
//Get All Blog List
export const getBlogs = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/blog/`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get Single Blog Details By Id
export const getBlogDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get All Blog Category List
export const getBlogCategories = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/blogCategory/`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};