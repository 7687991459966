import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

import { getBlogs } from "../service/api";
import { IMAGE_URL } from "../config";

const BlogsContainer = () => {
    // States
    const [bigBlockData, setBigBlockData] = useState(null);
    const [smallBlockData, setSmallBlockData] = useState(null);


    useEffect(() => {
        getBlogsListFunction()
    }, []);

    /* GET BLOGS LISTING */
    const getBlogsListFunction = () => {
        const obj = {};
        obj.page = 1;
        obj.sizePerPage = 5;

        getBlogs(obj).then((res) => {
            if (res?.status) {
                let tempSmallBlock = [];
                res?.data?.docs?.forEach((element, index) => {
                    if (index === 0) {
                        setBigBlockData(element)
                    } else {
                        tempSmallBlock.push(element)
                    }
                })

                setSmallBlockData(tempSmallBlock)
            }
        }).catch(err => {
            console.log("err", err)
        })
    }

    return <div className="row">
        <h1 className="heading">The Latest Healthcare News</h1>

        <div className="col-xl-6 col-lg-6 col-sm-12">
            <Link
                to={'/blog-detail/' + bigBlockData?._id}
                style={{ textDecoration: "none", color: "#213360" }}
            >
                <div className="main-blog">
                    <img
                        src={IMAGE_URL + "/" + bigBlockData?.image}
                        className="blog-img"
                        alt={bigBlockData?.name}
                    />
                    <div className="description">
                        <h5 className="heading">
                            {bigBlockData?.name}
                        </h5>
                        <p className="text truncate-text">
                            {ReactHtmlParser(bigBlockData?.description)}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
        <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="blog-list">
                {smallBlockData?.map((element, index) => {
                    return <Link
                        to={'/blog-detail/' + element?._id}
                        style={{ textDecoration: "none", color: "#213360" }}
                    >
                        <div className="small-blog">
                            <img
                                src={IMAGE_URL + "/" + element?.image}
                                className="blog-img"
                                alt={element?.name}
                            />
                            <div className="description">
                                <h5 className="heading">
                                    {element?.name}
                                </h5>
                                <p className="text truncate-text">
                                    {ReactHtmlParser(element?.description)}
                                </p>
                            </div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    </div>
}

export default BlogsContainer;