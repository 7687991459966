/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../../config";

/* BOOSTRAP COMPONENTS */
import { Button, Form } from "react-bootstrap";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";

/* ICONS */
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import { bookAnAppointment } from "../../../service/api";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* VALIDATION SCHEMA FOR FORM */
const contactUsFormValidation = yup.object().shape({
  firstName: yup.string().trim()
    .strict(true, "First Name is required")
    .min(1, 'First Name is required').required("First Name is required."),
  lastName: yup.string().trim()
    .strict(true, "Last Name is required")
    .min(1, 'Last Name is required').required("Last Name is required."),
  email: yup.string().email().trim()
    .strict(true, "Email Address is required")
    .min(1, 'Email Address is required').required("Email Address is required."),
  phone: yup.string().trim()
    .strict(true, "Phone Number is required")
    .min(1, 'Phone Number is required').required("Phone Number is required."),
  programOfInterest: yup.object().required("Program of Interest is required."),
  permissionToText: yup.boolean().required("Permission to Text is required."),
});

const programOptions = [
  {
    value: "Nursing Assistant Training Program",
    label: "Nursing Assistant Training Program",
  },
  {
    value: "Licensed Vocational Nurse Program",
    label: "Licensed Vocational Nurse Program",
  },
  {
    value: "Medical Office Administrative Program",
    label: "Medical Office Administrative Program",
  },
  {
    value: "Medical Assistant Program",
    label: "Medical Assistant Program",
  },
];

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const RequestInformation = () => {

  const BookAnAppointmentComponent = () => {

    const [formLoading, setFormLoading] = useState(false);

    /* HANDLE LOGIN FORM USING FORMIK */
    const formikFormFunction = useFormik({
      /* SET INITIAL VALUES FOR LOGIN FORM */
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        programOfInterest: "",
        permissionToText: ""
      },
      /* VALIDATION SCHEMA  */
      validationSchema: contactUsFormValidation,
      /* HANDLE FORM SUBMIT */
      onSubmit: (values) => {
        const obj = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          programOfInterest: values.programOfInterest.value,
          permissionToText: values.permissionToText
        }
        /* SET LOADING AS TRUE */
        setFormLoading(true);
        /* CALL LOGIN API */
        bookAnAppointment(obj)
          .then((res) => {
            /* CHECK RESPONSE STATUS */
            if (res?.status) {
              /* SUCCESS NOTIFICATION */
              toast.success(res?.message);
            } else {
              /* ERROR NOTIFICATION */
              toast.error(res?.message);
            }
            setFormLoading(false);
          })
          .catch((e) => {
            setFormLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            formikFormFunction.handleReset();
            setFormLoading(false);
          });
      },
    });


    return <>
      <form onSubmit={formikFormFunction.handleSubmit} id="bookingAppointmentForm">
        <div className="row mt-2">
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                name="firstName"
                id="firstName"
                value={formikFormFunction.values.firstName}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.firstName &&
                formikFormFunction.touched.firstName && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.firstName}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                name="lastName"
                id="lastName"
                value={formikFormFunction.values.lastName}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.lastName &&
                formikFormFunction.touched.lastName && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.lastName}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                name="email"
                id="emailAddress"
                value={formikFormFunction.values.email}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.email &&
                formikFormFunction.touched.email && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.email}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <PhoneInput
                className="phone-control"
                defaultCountry="US"
                placeholder="Enter phone number"
                value={formikFormFunction.values.phone}
                onChange={(e) => {
                  formikFormFunction.setFieldValue("phone", e)
                }}
              />
              {formikFormFunction.errors.phone &&
                formikFormFunction.touched.phone && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.phone}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12">
            <div className="form-group">
              <Select
                options={programOptions}
                className="react-select"
                classNamePrefix="select-control"
                placeholder="Program of interest"
                value={formikFormFunction.values.programOfInterest}
                onChange={(e) => {
                  formikFormFunction.setFieldValue("programOfInterest", e)
                }}
              />
              {formikFormFunction.errors.programOfInterest &&
                formikFormFunction.touched.programOfInterest && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.programOfInterest}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">
                Do we have permission to text you?
              </label>
              <div className="checkbox-list">
                <Form.Check
                  type="radio"
                  label="Yes"
                  name="permissionToText"
                  className="checkbox-control"
                  value={true}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("permissionToText", e?.target?.value)
                  }}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name="permissionToText"
                  className="checkbox-control"
                  value={false}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("permissionToText", e?.target?.value)
                  }}
                />
               
              </div>
              {formikFormFunction.errors.permissionToText &&
                  formikFormFunction.touched.permissionToText && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.permissionToText}
                    </small>
                  )}
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12">
            {formLoading ? <Button className="req-info" type="button">
              Please wait..
              <ArrowRightIcon />
            </Button> : <Button type="submit" className="req-info">
              Request Information
              <ArrowRightIcon />
            </Button>}

          </div>
        </div>
      </form>
    </>
  }
  return (
    <AnimatePresence>
      <Helmet>
        <title>Request More Information | Clement Career College</title>
        <meta
          name="description"
          content="Request for knowing more information about the certified nursing training program and classes at Clement career college."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/contact-us"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Request Information"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">REQUEST INFORMATION</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <p className="text" style={{ fontWeight: "700" }}>
                  The best way to get to know Clement Career College is to see
                  Clement Career College!
                </p>
                <div className="new-para mt-0">
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Campus tours offered to fit your busy schedule
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Tours for prospective students begin with an information
                      session
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Brief Meeting with admission Representative or career
                      advisor.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Ending with a walking tour of the school
                    </p>
                  </div>
                  <p className="text">
                    If you’re interested in seeing what we have to offer, please
                    fill out and submit the form below.
                  </p>
                </div>
                <div className="green-box-form">
                  <div className="form">
                    <h2 className="heading">Book an Appointment</h2>
                    <BookAnAppointmentComponent />

                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default RequestInformation;
