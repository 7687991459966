import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const AdministrationStaff = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Administration Staff | Clement Career College</title>
        <meta
          name="description"
          content="Our Nurse Assistant staff and administration are experts in the health care department. Clement career college has experienced assistants. contact our team today."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/administration-staff"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Adminstration & Staff"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">ADMINISTRATION &amp; STAFF</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <img
                  src={`${IMAGE_URL}/banners/CCC_faculty_and_staff.webp`}
                  className="admin"
                  alt="Clement Career College Administration and Staff"
                />
                <div className="mb-2">
                  <h5 className="name">Emerita G. Goodrich</h5>
                  <p className="designation">Director of Nursing</p>
                  <p className="text">
                    Emerita G. Goodrich, RN, MSN-GNPc is the Director of Nursing
                    at Clement Career College. She brings with her forty-nine
                    years of nursing experience. In her first 25 years as an RN,
                    she mostly worked in ICU, Ambulatory care, and OB-GYN in
                    different roles as staff Nurse, Charge Nurse, and as
                    Supervisor for a Senior Nursing facility. She later became a
                    Gerontology Nurse Practitioner and worked full-time for more
                    than 16 years in Urology at the VA Hospital in Loma Linda
                    California, and continued to work as a part-time independent
                    contractor in the same department until she turned 70. It
                    seems retirement is not in her blood, and after 1 year of
                    rest, she decided to take on a new adventure as Director of
                    Nursing for the Vocational Nursing Program of Clement Career
                    College. Now in the Educational field of Nursing, she brings
                    with her countless experiences and sees her goal for the
                    school to influence, inspire and create an outstanding breed
                    of nurses, faculty, and staff. She is committed to providing
                    the students with a high-quality education so they may carry
                    on a legacy of nurturing care, ultra-high level of
                    knowledge, and skills to provide patient and family-centered
                    care in the community or wherever they may choose to be. She
                    has an MSN from UCLA and ANCC Board Certified in
                    Gerontology, a BSN from the University of the Philippines
                    College of Nursing, Diliman Quezon City, Philippines, and
                    graduated high school from Far Eastern University, Girls
                    High School, Manila Philippines. She is an active member of
                    the University of the Philippines International Nursing and
                    Healthcare Forum and former President and life member of the
                    University of the Philippines Alumni Association
                    International, former member of SUNA and CANP. A former LA
                    marathon runner, she now regularly swims for exercise and
                    enjoys her role as grandma to her 3 grandchildren who are
                    rapidly growing up.
                  </p>
                </div>
                <div className="mb-2">
                  <h5 className="name">Dr. Ramon Sison Geluz</h5>
                  <p className="designation">Campus Director/Dean</p>
                  <p className="text">
                    Dr. Ramon Sison Geluz is the multi-awarded Composer and
                    Librettist of the first originally written Filipino opera
                    “Karim at Jasmin” and other religious-themed musicals. His
                    publicly acclaimed works have been featured in different
                    community fundraising events by different church
                    communities, cultural organizations, including benefits for
                    the Filipino American Symphony Orchestra (FASO) and the Los
                    Angeles Philharmonic Orchestra. His years of education,
                    training and experience as a medical doctor and healthcare
                    educator have prepared him for this latest episode in his
                    life as the Campus Director and Dean of Clement Career
                    College in Gardena, CA.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default AdministrationStaff;
