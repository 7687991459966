import PageSidebar from "../../components/sidebar";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../config";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const CreditTransferPolicy = () => {
    return (
      <AnimatePresence>
        <Helmet>
          <title>Credit Transfer Policy | Clement Career College</title>
          <link
            rel="canonical"
            href="https://www.clementcareercollege.org/credit-transfer-policy"
          />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        </Helmet>

        <motion.section
          variants={containerAnimation}
          initial="hidden"
          animate="visible"
          className="other-pages"
        >
          {/* BANNER */}
          <motion.div variants={animation} className="banner-with-image">
            <div className="image">
              <img
                src={`${IMAGE_URL}/banners/request-info-banner.webp`}
                alt="Credit Tranfer Policy"
              />
            </div>

            <div className="head">
              <div className="container">
                <h1 className="heading">CREDIT TRANSFER POLICY</h1>
              </div>
            </div>
          </motion.div>

          {/* DESCRIPTION */}
          <motion.div variants={animation} className="page-details">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-sm-12">
                  <h5 className="heading mt-0">
                    Notice Concerning transferability of credits and credentials
                    earned at our institution:
                  </h5>
                  <p className="text mt-2">
                    The transferability of credits you earn at Clement Career
                    College (CLCC) is at the complete discretion of an
                    institution to which you may seek to transfer. Acceptance of
                    the diploma or certificate of completion you earn in the
                    programs offered at CLCC is also at the complete discretion
                    of the institution to which you may seek to transfer. If the
                    diploma or certificate of completion that you earn at this
                    institution are not accepted at the institution to which you
                    seek to transfer, you may be required to repeat some or all
                    your course work at that institution. For this reason, you
                    should make certain that your attendance at this institution
                    will meet your educational goals. This may include
                    contacting an institution to which you may seek to transfer
                    after attending CLCC to determine if your diploma or
                    certificate of completion will transfer.
                  </p>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12">
                  <PageSidebar />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.section>
      </AnimatePresence>
    );
};

export default CreditTransferPolicy;