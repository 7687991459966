import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import ModalImage from "react-modal-image";
import { IMAGE_URL } from "../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../components/sidebar";
import GetInTouchBox from "../../components/get-in-touch";

/* ICONS */
import { CheckIcon } from "@heroicons/react/24/solid";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const AboutUs = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>About Us | Clement Career College</title>
        <meta
          name="description"
          content="Getting a healthcare career begins with nursing education programs and a CNA training program offered by Clement Career College."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/about-us"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="About Us"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">ABOUT US</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <img
                  src={require("../../assets/about-us.webp")}
                  className="admin"
                  alt="About Us"
                />
                <p className="text">
                  We are dedicated to the personal success of every student.
                  Through this commitment, we have developed a comprehensive
                  curriculum designed to prepare students for a satisfying
                  career in a variety of health-related fields. We strive to
                  create a fulfilling training environment that meets the needs
                  of our students. We believe that learning is enhanced by a
                  positive relationship between students and faculty.
                </p>
                <p className="text">
                  Thank you for visiting our website. We encourage you to call
                  and make an appointment with our admission department to start
                  your new future today!
                </p>
                <div className="new-para">
                  <h5 className="heading">Our Mission</h5>
                  <p className="text">
                    Clement Career College aims to provide quality education and
                    training in the health care industry among diverse
                    population groups. Clement Career College is committed to
                    providing the infrastructure that will equip our students
                    with critical thinking, professional behavior, technological
                    competence to the health care needs of their clients.
                  </p>
                </div>
                <div className="new-para">
                  <h5 className="heading">Our Philosophy</h5>
                  <p className="text">
                    Clement Career College aims to provide curricular programs
                    and instructional activities that address the various
                    learning styles of the students. It recognizes its faculty
                    and staff as caring facilitators in our students’ academic
                    learning and aspires to empower the students with the
                    knowledge and critical thinking to make ethical decisions in
                    all aspects of their lives.
                  </p>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Learning is as an ongoing process producing the desired
                      change in behavior resulting from the acquisition of
                      knowledge and skills.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      Nurses play an important role in the promotion and
                      restoration of health.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      The instructors are the facilitators of the learning
                      process and a resource person who guides the student in
                      attaining the highest academic level.
                    </p>
                  </div>
                </div>
                <div className="new-para">
                  <h5 className="heading">Our Values</h5>
                  <div className="point mt-2">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Ethics and integrity</b> in our faculty, our programs,
                      and our students. Our paths of learning stem from an
                      earnest desire for students to succeed in their fields of
                      study and future careers.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Outstanding staff for student services,</b> which is a
                      result of our enthusiasm for serving our students and
                      their in-depth knowledge and excitement about the career
                      training programs we offer.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Higher educational standards</b> that allow us to be an
                      educational leader in our community by offering programs
                      that will give our students an advantage in today’s highly
                      competitive workplace.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>A legacy of dedication and pride,</b> continuing our
                      long-standing traditions and rich history of being a
                      college that cares.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Relationships with students, faculty, and staff,</b>{" "}
                      working together to build a supportive and encouraging
                      environment that is built on integrity, respect, and
                      trust.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>
                        Development of our students, faculty, and staff, forever{" "}
                      </b>{" "}
                      striving to empower each person to make a difference.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Excellence</b> in student success outcomes. Our faculty
                      and staff strive for excellence, which reflects our
                      expectations for hard work and passion for higher
                      education.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Learning </b> supported by applied and active curricula
                      designed to mentor students in their individual learning
                      and success.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Diversity</b> as the foundation for appreciating
                      differences and supporting each other in an inclusive
                      learning environment.
                    </p>
                  </div>
                  <div className="point">
                    <div className="box">
                      <CheckIcon />
                    </div>
                    <p className="text">
                      <b>Leadership</b> in the community, and beyond.
                    </p>
                  </div>
                  <p className="text">
                    <b>An innovative culture</b> that promotes creativity,
                    vision, and perseverance.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sideabout-image">
                  <ModalImage
                    small={`${IMAGE_URL}/banners/About-Clement-Career-College.webp`}
                    large={`${IMAGE_URL}/banners/About-Clement-Career-College.webp`}
                    alt="About Clement Career College"
                  />
                </div>
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default AboutUs;
