import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";

/* ICONS */
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const PageSidebar = () => {
    return (
      <div className="sticky-top">
        <Card className="campus-tour-card">
          <Card.Body className="campus-tour-cardbody">
            <h5 className="heading">Tour the Campus</h5>
            <p className="text">
              Please feel welcome to contact us and visit our beautiful campus
              in sunny Gardena, California.
            </p>
            <Link to="/request-information" style={{ textDecoration: 'none' }}>
              <Button className="action-btn">
                Schedule A Tour <ArrowRightIcon className="right-arrow" />
              </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card className="apply-online-card">
          <Card.Body className="apply-online-cardbody">
            <h5 className="heading">Apply Online Today</h5>
            <p className="text">
              Ready to enroll in your future? Apply online today and start your
              journey with us.
            </p>
            <Link to="/apply-online" style={{ textDecoration: 'none' }}>
              <Button className="action-btn">
                Apply Online <ArrowRightIcon className="right-arrow" />
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </div>
    );
};

export default PageSidebar;