/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { useParams } from "react-router-dom"

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";

/* ICONS */
import { CheckIcon } from "@heroicons/react/24/solid";

import ReactHtmlParser from 'react-html-parser';

// IMPORT APIs
import { getBlogDetailsById } from "../../../service/api";
import { IMAGE_URL } from "../../../config";


const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const BlogDetails = () => {
  const { id } = useParams()
  const getId = id?.split('&')
  const [loading, setLoading] = useState(true);
  const [blogDetails, setBlogDetails] = useState(null)

  useEffect(() => {
    getBlogDetails();
  }, [getId[0]]);

  // Get Blog Details API
  const getBlogDetails = () => {
    setLoading(true);
    getBlogDetailsById(getId[0])
      .then((res) => {
        if (res?.status) {
          setBlogDetails(res?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      })
      .finally((err) => {
        setLoading(false);
      });

    return (() => {
      setBlogDetails(null)
    })
  }


  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="blog-details"
      >
        <div className="container">
          <div className="row">
            <motion.div
              variants={animation}
              className="col-xl-8 col-lg-8 col-sm-12"
            >
              <h2>
                {blogDetails?.name}
              </h2>
              <img
                src={IMAGE_URL + "/" + blogDetails?.image}
                className="blog-img mb-3 mt-3"
                alt={blogDetails?.name}
              />
              <div className="blog-detail-desc">
                <div className="description-inner">
                  <p>{ReactHtmlParser(blogDetails?.description)}</p>
                </div>
              </div>
            </motion.div>
            <motion.div
              variants={animation}
              className="col-xl-4 col-lg-4 col-sm-12"
            >
              <PageSidebar />
            </motion.div>
          </div>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

export default BlogDetails;