import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import ApplyOnlineCard from "../../../components/apply-online";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const ApplyOnline = () => {
    return (
      <AnimatePresence>
        <Helmet>
          <title>Apply Now Online Today | Clement Career College</title>
          <meta
            name="description"
            content="You can apply now online today for the Certified nursing assistant course in California. Become a nurse assistant in 6-week classes. Enroll now and more visit our website."
          />
          <link
            rel="canonical"
            href="https://www.clementcareercollege.org/apply-online"
          />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        </Helmet>

        <motion.section
          variants={containerAnimation}
          initial="hidden"
          animate="visible"
          className="other-pages"
        >
          {/* BANNER */}
          <motion.div variants={animation} className="banner-with-image">
            <div className="image">
              <img
                src={`${IMAGE_URL}/banners/request-info-banner.webp`}
                alt="Apply Online"
              />
            </div>

            <div className="head">
              <div className="container">
                <h1 className="heading">APPLY ONLINE</h1>
              </div>
            </div>
          </motion.div>

          {/* GET IN TOUCH BOX */}
          <motion.div variants={animation} className="get-in-touch with-b">
            <div className="container">
              <ApplyOnlineCard />
            </div>
          </motion.div>
        </motion.section>
      </AnimatePresence>
    );
};

export default ApplyOnline;