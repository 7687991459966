/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import PhoneInput from "react-phone-number-input";
import { Link } from 'react-router-dom';

/* BOOTSTRAP COMPONENTS */
import { Button, Card } from "react-bootstrap";

/* ICONS */
import { EnvelopeIcon, PhoneIcon, MapPinIcon, ClockIcon } from "@heroicons/react/24/solid";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import { programInformation } from "../service/api";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* VALIDATION SCHEMA FOR FORM */
const contactUsFormValidation = yup.object().shape({
  firstName: yup.string().trim()
    .strict(true, "First Name is required")
    .min(1, 'First Name is required').required("First Name is required."),
  lastName: yup.string().trim()
    .strict(true, "Last Name is required")
    .min(1, 'Last Name is required').required("Last Name is required."),
  email: yup.string().email().trim()
    .strict(true, "Email Address is required")
    .min(1, 'Email Address is required').required("Email Address is required."),
  phone: yup.string().trim()
    .strict(true, "Phone Number is required")
    .min(1, 'Phone Number is required').required("Phone Number is required."),
  programOfInterest: yup.object().required("Program of Interest is required."),
  findUs: yup.object().required("Find Us is required.")
});


const programOptions = [
  {
    value: "Nursing Assistant Training Program",
    label: "Nursing Assistant Training Program",
  },
  {
    value: "Licensed Vocational Nurse Program",
    label: "Licensed Vocational Nurse Program",
  },
  {
    value: "Medical Office Administrative Program",
    label: "Medical Office Administrative Program",
  },
  {
    value: "Medical Assistant Program",
    label: "Medical Assistant Program",
  },
];

const findUsOptions = [
  {
    value: "Google",
    label: "Google",
  },
  {
    value: "Alumni Student",
    label: "Alumni Student",
  },
];

const GetInTouchBox = () => {

  const ProgramInformationFormComponent = () => {
    const [formLoading, setFormLoading] = useState(false);

    /* HANDLE LOGIN FORM USING FORMIK */
    const formikFormFunction = useFormik({
      /* SET INITIAL VALUES FOR LOGIN FORM */
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        programOfInterest: "",
        findUs: ""
      },
      /* VALIDATION SCHEMA  */
      validationSchema: contactUsFormValidation,
      /* HANDLE FORM SUBMIT */
      onSubmit: (values) => {
        const obj = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          programOfInterest: values.programOfInterest.value,
          findUs: values.findUs.value
        }
        /* SET LOADING AS TRUE */
        setFormLoading(true);
        /* CALL LOGIN API */
        programInformation(obj)
          .then((res) => {
            /* CHECK RESPONSE STATUS */
            if (res?.status) {
              /* SUCCESS NOTIFICATION */
              toast.success(res?.message);
            } else {
              /* ERROR NOTIFICATION */
              toast.error(res?.message);
            }
            setFormLoading(false);
          })
          .catch((e) => {
            setFormLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            formikFormFunction.handleReset();
            setFormLoading(false);
          });
      },
    });

    return <>
      <form className="contact-form" onSubmit={formikFormFunction.handleSubmit} id="contactUsForm">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                name="firstName"
                id="firstName"
                value={formikFormFunction.values.firstName}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.firstName &&
                formikFormFunction.touched.firstName && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.firstName}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                name="lastName"
                id="lastName"
                value={formikFormFunction.values.lastName}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.lastName &&
                formikFormFunction.touched.lastName && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.lastName}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                name="email"
                id="emailAddress"
                value={formikFormFunction.values.email}
                onChange={formikFormFunction.handleChange}
              />
              {formikFormFunction.errors.email &&
                formikFormFunction.touched.email && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.email}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <PhoneInput
                className="phone-control"
                defaultCountry="US"
                placeholder="Enter phone number"
                value={formikFormFunction.values.phone}
                onChange={(e) => {
                  formikFormFunction.setFieldValue("phone", e)
                }}
              />
              {formikFormFunction.errors.phone &&
                formikFormFunction.touched.phone && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.phone}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <Select
                options={programOptions}
                className="react-select"
                classNamePrefix="select-control"
                placeholder="Program of interest"
                value={formikFormFunction.values.programOfInterest}
                onChange={(e) => {
                  formikFormFunction.setFieldValue("programOfInterest", e)
                }}
              />
              {formikFormFunction.errors.programOfInterest &&
                formikFormFunction.touched.programOfInterest && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.programOfInterest}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="form-group">
              <Select
                options={findUsOptions}
                className="react-select"
                classNamePrefix="select-control"
                placeholder="How did you find us?"
                value={formikFormFunction.values.findUs}
                onChange={(e) => {
                  formikFormFunction.setFieldValue("findUs", e)
                }}
              />
              {formikFormFunction.errors.findUs &&
                formikFormFunction.touched.findUs && (
                  <small style={{ color: "red" }}>
                    {formikFormFunction.errors.findUs}
                  </small>
                )}
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12">
            {formLoading ? <Button className="action-btn" type="button">Please wait..</Button> : <Button className="action-btn" type="submit">Request Information</Button>}
            <p className="small-text">
              By clicking above, I consent to receive calls and/or text
              messages* by a school representative with information for
              educational opportunities at the number(s) provided above. I
              understand calls may be initiated by an automated telephone
              dialing system. I need not grant this consent to receive
              information or to be eligible to enroll with Clement Career
              College. I also understand that if I no longer wish to
              receive text messages from Clement Career College, I can
              text back “Stop” at any time to unsubscribe from the
              service. *Standard rates may apply.
            </p>
          </div>
        </div>
      </form>
    </>
  }

  return (
    <Card className="contact-box-card">
      <Card.Body className="contact-box-cardbody">
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-sm-12">
            <h2 className="heading">
              Want More Information About Our Programs ?
            </h2>
            <ProgramInformationFormComponent />
          </div>
          <div className="col-xl-5 col-lg-5 col-sm-12">
            <Card className="quick-contacts-card">
              <Card.Body className="quick-contacts-cardbody">
                <h5 className="heading">Quick Contacts</h5>
                <p className="text">
                  Please feel free to contact our friendly staff with any
                  questions.
                </p>
                <div className="contact-details">
                  <a
                    href="mailto:contact@clementcareercollege.net"
                    className="cta"
                  >
                    <EnvelopeIcon />
                    <p>Email: contact@clementcareercollege.net</p>
                  </a>
                  <a href="tel:+15625792111" className="cta">
                    <PhoneIcon />
                    <p>Telephone Line: (562) 579-2111</p>
                  </a>
                  <a
                    href="https://goo.gl/maps/fxup59VabBxZjadcA"
                    target="_blank"
                    className="cta"
                    rel="noreferrer"
                  >
                    <MapPinIcon />
                    <p>
                      1225 West 190th Street, Suite 375 Gardena, CA 90248,
                      United States.
                    </p>
                  </a>
                  <a
                    href="https://goo.gl/maps/fxup59VabBxZjadcA"
                    target="_blank"
                    className="cta"
                    rel="noreferrer"
                  >
                    <ClockIcon />
                    <p>
                      Mon - Fri: 9:00 am - 5:00 pm
                    </p>
                  </a>
                  <Link to="/contact-us">
                    <Button className='contact-btn'>Contact Us</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GetInTouchBox;
