import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ModalImage from "react-modal-image";
import { IMAGE_URL } from "../../../config"; 

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const LicensedVocationalNurseProgram = () => {
  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="program"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/vocational-nursing-banner.jpg`}
              alt="Vocational Nursing Program"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">VOCATIONAL NURSING PROGRAM</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="program-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <p className="big-text">
                  Clement Career College’s VN Program was designed to help you
                  achieve your dream career through leadership, critical
                  thinking, and proper hands-on training. Our professionally
                  trained faculty will guide you and provide you with all the
                  necessary tools to becoming a successful nurse.
                </p>
              </div>
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div>
                  <h2 className="heading">
                    What Does A Licensed Vocational Nurse Do?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Licensed Vocational Nurses or Licensed Practical Nurses
                      practices under the supervision of a physician or
                      registered nurse (RN.) LVNs provide basic bedside care to
                      patients. Their roles and responsibilities can include
                      monitoring blood pressure, record patient histories,
                      helping patients get dressed, deal with ailments, and make
                      sure patients are comfortable.
                    </p>
                    <p className="text">Duties of Vocational Nurses are:</p>
                    <ul className="list">
                      <li>Recording Vital Signs</li>
                      <li>Administer Medications</li>
                      <li>Collecting Samples for Routine Lab Tests</li>
                      <li>
                        Leadership, Supervision, and Proper Patient Interaction
                        Skills
                      </li>
                      <li>Assisting Physicians with Patient Examinations</li>
                      <li>
                        Recording Patient History and Personal Information
                      </li>
                      <li>
                        Administering Injections or Medications (as permitted by
                        state law)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    Where Does A Licensed Vocational Nurse Work ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Licensed Vocational Nurses or Practical Nurses work in a
                      variety of settings. They can work in nursing homes,
                      extended care facilities, physicians’ offices, hospitals,
                      and private homes.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Are The Admission Requirements ?
                  </h2>
                  <div className="mt-2">
                    <ul className="list mt-0">
                      <li>
                        Must be at least 17 years old; 16-year-old who have
                        graduated from high school or passed the California High
                        School Proficiency Exam (CHSPE) must obtain parental
                        consent. (Note: Must be 17 years old by the end of
                        program)
                      </li>
                      <li>
                        High school transcript, or certificate of high school
                        equivalency, or GED, or certificate of evaluation of
                        transcript or diploma if a foreign graduate.
                      </li>
                      <li>
                        Candidate must provide a Valid State ID or Passport and
                        Social Security Card
                      </li>
                      <li>
                        Proof of completion of Nurse Assistant Training Program.
                      </li>
                      <li>
                        Must pass the CLCC-VN Nurse Entrance Exam - Test of
                        Essential Academic Skills (TEAS) which measures Math,
                        Reading and English & Language Usage.
                      </li>
                    </ul>
                    <p className="text">
                      Failure to achieve a score of 65% on any of the content
                      areas in the CLCC-VN Nurse Entrance Exam or the TEAS, the
                      prospective VN student will be given a chance to take a
                      written exam two weeks after the first attempt. (no more
                      than 2 takes)
                    </p>
                    <ul className="list">
                      <li>Complete student registration form</li>
                      <li>
                        Candidate must submit a letter of application to the
                        School Director. This letter will briefly explain the
                        candidate’s interest and intention to be admitted in the
                        vocational nursing program. Letter must be formally
                        typewritten in a 11-12pt. size font and doubled spaced
                      </li>
                      <li>
                        Two (2) letters of recommendation from a former or
                        current teacher/instructor and from a supervisor from
                        work. If not working, a letter from a long-time friend
                        is acceptable. Letter from an immediate family member or
                        a relative is not acceptable
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    How Long Does It Take To Complete The Licensed Vocational
                    Program ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">51 weeks</p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Types Of Courses Are Taught ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      This course introduces the beginning nursing student to
                      the historical background of nursing. It focuses on the
                      basic health needs of individuals in optimal wellness. The
                      course covers foundation skills, personal care, rest and
                      mobility, comfort, elimination and nutrition. Assisting
                      with special tests and examinations, infection control and
                      therapeutic communication are also included in this
                      course.
                    </p>
                    <ul className="list mt-0">
                      <li>Anatomy and Physiology</li>
                      <li>Fundamentals of Nursing</li>
                      <li>Pharmacology</li>
                      <li>Medical Surgery</li>
                      <li>Leadership</li>
                      <li>Maternal & Health Nursing</li>
                      <li>Pediatric Nursing</li>
                      <li>Supervision</li>
                      <li>Critical Thinking</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">Where Can I Take My Classes ?</h2>
                  <div className="mt-2">
                    <p className="text">
                      Classes for the Vocational Nurse Program is taught at our
                      school campus located at 1225 West 190th Street, Suite 375
                      Gardena, California 90248.
                    </p>
                    <p className="text">
                      Clinical practice will take place at approved clinical
                      sites that are in partnership with Clement Career College.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sideabout-image">
                  <ModalImage
                    small={`${IMAGE_URL}/banners/vocational-nursing-flyer.webp`}
                    large={`${IMAGE_URL}/banners/vocational-nursing-flyer.webp`}
                    alt="Nursing Assistant Program"
                  />
                </div>
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default LicensedVocationalNurseProgram;
