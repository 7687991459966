import { AnimatePresence, motion } from "framer-motion";
import PageSidebar from "../../components/sidebar";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../config";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const PrivacyPolicy = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Privacy Policy | Clement Career College</title>
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/privacy-policy"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Privacy Policy"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">PRIVACY POLICY</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <h5 className="heading mt-0">
                  What Personally Identifiable Information Does Clement Career
                  College Collect From Me?
                </h5>
                <p className="text mt-2">
                  Clement Career College collects information in several ways
                  from different parts of our websites. Some personal
                  information is gathered if you register with us. During
                  registration, Clement Career College may ask for personal
                  information such as your name, email address, gender,
                  zip/postal code, occupation, industry and personal interests.
                </p>
                <p className="text">
                  In addition to registration, we may ask you for personal
                  information at other times, including, (but not limited to),
                  when you download a file (such as a .PDF file) from us, when
                  you ask us to contact you, when you join our mailing list; and
                  when you report a problem with one of our sites or services.
                  If you contact Clement Career College, we may keep a record of
                  that correspondence. Clement Career College may also
                  occasionally ask users to complete surveys that we use for
                  research. Wherever Clement Career College collects personal
                  information we make an effort to include a link to this
                  Privacy Policy on that page.
                </p>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    What Are Cookies And How Does Clement Career College Use
                    Them?
                  </h5>
                  <p className="text mt-2">
                    As part of offering and providing customizable and
                    personalized services, Clement Career College may use
                    cookies to store and sometimes track information about you.
                    A cookie is a small amount of data that is sent to your
                    browser from our web server and stored on your computer’s
                    hard drive.
                  </p>
                  <p className="text">Generally, we may use cookies to:</p>
                  <ul className="list mt-0">
                    <li>
                      Remind us of who you are and to access your information
                      (stored on our computers) in order to deliver to you a
                      better and more personalized service. For example, a
                      cookie is set when you register or “Sign In” and is
                      modified when you “Sign Out” of our services.
                    </li>
                    <li>
                      Automate certain areas of the Clement Career College
                      websites or online forms.
                    </li>
                    <li>
                      Estimate our audience size. Each browser accessing the
                      Clement Career College website is given a unique cookie
                      which is then used to determine the extent of repeat
                      usage, usage by a registered user versus by an
                      unregistered user, and to help target advertisements based
                      on user interests and behavior.
                    </li>
                    <li>
                      Measure certain traffic patterns, which areas of the
                      Clement Career College website you have visited, and your
                      visiting patterns in the aggregate. We use this research
                      to understand how our users’ habits are similar or
                      different from one another so that we can make each new
                      experience on the Clement Career College website a better
                      one. We may use this information to better personalize the
                      content, and promotions that you and other users will see
                      on our sites.
                    </li>
                  </ul>
                  <p className="text">
                    You also have choices with respect to cookies. By modifying
                    your browser preferences, you have the choice to accept all
                    cookies, to be notified when a cookie is set, or to reject
                    all cookies. Please refer to your browser’s help page for
                    further information about your options for controlling or
                    monitoring cookies.
                  </p>
                </div>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    With Whom Does Clement Career College Share My Information?
                  </h5>
                  <p className="text mt-2">
                    Clement Career College will not disclose any of your
                    personally identifiable information except when we have your
                    permission. Clement Career College may disclose account
                    information in special cases when we have reason to believe
                    that disclosing this information is necessary to identify,
                    contact or bring legal action against someone who may be
                    violating Clement Career College’s Terms of Service or may
                    be causing injury to or interference with (either
                    intentionally or unintentionally) Trinity School of Health
                    and Allied Science’s rights or property, other Clement
                    Career College users, or anyone else that could be harmed by
                    such activities. Clement Career College may disclose or
                    access account information when we believe in good faith
                    that the law requires it and for administrative and other
                    purposes that we deem necessary to maintain, service, and
                    improve our products and services.
                  </p>
                  <p className="text">
                    Clement Career College does not sell or rent user
                    information to anyone. We will notify you at the time of
                    data collection or transfer if your data will be shared with
                    a third party and you will always have the option of not
                    permitting the transfer. If you do not wish to allow your
                    data to be shared, you can choose not to use a particular
                    service or not to participate in certain promotions or
                    contests.
                  </p>
                </div>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    How Does Clement Career College Secure My Information?
                  </h5>
                  <p className="text mt-2">
                    We take precautions to protect your information. When you
                    submit sensitive information via the website, your
                    information is protected both online and offline. Wherever
                    we collect sensitive information (such as credit card data),
                    that information is encrypted and transmitted to us in a
                    secure way. While we use encryption to protect sensitive
                    information transmitted online, we also protect your
                    information offline. Only employees who need the information
                    to provide you with the service requested have access to
                    your information. The computers/servers on which we store
                    personally identifiable information are kept in a secure
                    environment.
                  </p>
                </div>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    How Could My Submitted Information Be Corrected Or What Do I
                    Do If I Have Concerns Over Misuse Of My Personal Data?
                  </h5>
                  <p className="text mt-2">
                    You may opt out of any future contacts from us at any time.
                    You can do the following at any time by contacting us via
                    the email address or phone number provided on our website:
                  </p>
                  <ul className="list">
                    <li>See what data we have about you, if any.</li>
                    <li>Change/correct any data we have about you.</li>
                    <li>Have us delete any data we have about you.</li>
                    <li>
                      Express any concern you have about our use of your data.
                    </li>
                  </ul>
                </div>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    How Will I Be Notified Of Any Privacy Policy Changes?
                  </h5>
                  <p className="text mt-2">
                    If a substantial privacy notice revision occurs, you will be
                    notified by the university and the new privacy statement
                    will be available for review.
                  </p>
                </div>

                <div className="mt-4">
                  <h5 className="heading mt-0">
                    What Else Should I Know About My Privacy?
                  </h5>
                  <p className="text mt-2">
                    Please keep in mind that whenever you voluntarily disclose
                    personal information online – for example on message boards,
                    through email, or in instant message or chat areas – that
                    this information can be collected and used by others. In
                    short, if you post personal information online that is
                    accessible to the public, you may receive unsolicited
                    messages from other parties in return.
                  </p>
                  <p className="text mt-2">
                    Unfortunately, no data transmission over the Internet can be
                    guaranteed to be 100% secure. As a result, while we strive
                    to protect your personal information, Clement Career College
                    cannot ensure or warrant the security of any information you
                    transmit to us or from our online products or services, and
                    you do so at your own risk. Once we receive your
                    transmission, we make our best effort to ensure its security
                    on our systems.
                  </p>
                  <p className="text mt-2">
                    Ultimately, you are solely responsible for maintaining the
                    secrecy of your passwords and/or any account information.
                    Please be careful and responsible whenever you’re online.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default PrivacyPolicy;
