/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import PhoneInput from "react-phone-number-input";

import Select from 'react-select'
import countryList from 'react-select-country-list'


/* BOOTSTRAP COMPONENTS */
import { Card, Form, Button } from "react-bootstrap";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import { transcriptForm } from "../service/api";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* VALIDATION SCHEMA FOR FORM */
const transcriptFormValidation = yup.object().shape({
  firstName: yup.string().trim()
    .strict(true, "First Name is required")
    .min(1, 'First Name is required').required("First Name is required."),
  middleName: yup.string().trim()
    .strict(true, "Middle Name is required")
    .min(1, 'Middle Name is required').required("Middle Name is required."),
  lastName: yup.string().trim()
    .strict(true, "Last Name is required")
    .min(1, 'Last Name is required').required("Last Name is required."),
  email: yup.string().email().trim()
    .strict(true, "Email Address is required")
    .min(1, 'Email Address is required').required("Email Address is required."),
  phone: yup.string().trim()
    .strict(true, "Phone Number is required")
    .min(1, 'Phone Number is required').required("Phone Number is required."),
  address: yup.string().trim()
    .strict(true, "Address is required")
    .min(1, 'Address is required').required("Address is required."),
  suiteNumber: yup.string().trim()
    .strict(true, "Suite Number is required")
    .min(1, 'Suite Number is required'),
  city: yup.string().trim()
    .strict(true, "City is required")
    .min(1, 'City is required').required("City is required."),
  state: yup.string().trim()
    .strict(true, "State is required")
    .min(1, 'State is required').required("State is required."),
  country: yup.object()
    .strict(true, "Country is required")
    .required("Country is required."),
  zipCode: yup.string().trim()
    .min(1, 'Zip Code is required').required("Zip Code is required."),
  studentIdNumber: yup.string().trim()
    .min(1, 'Student ID Number is required').required("Student ID Number is required."),
  totalCopiesRequested: yup.number()
    .strict(true, "Total Copies Requested is required")
    .min(1, 'Total Copies Requested is required').required("Total Copies Requested is required."),
  sendTo: yup.string().trim()
    .strict(true, "Send To is required")
    .min(1, 'Send To is required').required("Send To is required."),
  attn: yup.string().trim()
    .strict(true, "Attn is required")
    .min(1, 'Attn is required').required("Attn is required."),
  sendToAddress: yup.string().trim()
    .strict(true, "Address is required")
    .min(1, 'Address is required').required("Address is required."),
  sendToSuiteNumber: yup.string().trim()
    .strict(true, "Suite Number is required")
    .min(1, 'Suite Number is required'),
  sendToCity: yup.string().trim()
    .strict(true, "City is required")
    .min(1, 'City is required').required("City is required."),
  sendToState: yup.string().trim()
    .strict(true, "State is required")
    .min(1, 'State is required').required("State is required."),
  sendToZipCode: yup.string().trim()
    .min(1, 'Zip Code is required').required("Zip Code is required."),
  sendToCountry: yup.object()
    .strict(true, "Country is required")
    .required("Country is required."),
});


const RequestForm = () => {

  const [formLoading, setFormLoading] = useState(false);
  const options = useMemo(() => countryList().getData(), [])

  /* HANDLE LOGIN FORM USING FORMIK */
  const formikFormFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      suiteNumber: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      studentIdNumber: "",
      totalCopiesRequested: "",
      sendTo: "",
      attn: "",
      sendToAddress: "",
      sendToSuiteNumber: "",
      sendToCity: "",
      sendToState: "",
      sendToZipCode: "",
      sendToCountry: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: transcriptFormValidation,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country?.value,
        zipCode: String(values.zipCode),
        studentIdNumber: String(values.studentIdNumber),
        totalCopiesRequested: String(values.totalCopiesRequested),
        sendToEmailAddress: values.sendTo,
        attn: values.attn,
        sendToAddress: values.sendToAddress,
        sendToCity: values.sendToCity,
        sendToState: values.sendToState,
        sendToZipCode: String(values.sendToZipCode),
        sendToCountry: values.sendToCountry?.value,
      }
      if (values.sendToSuiteNumber) {
        obj.sendToSuiteNumber = values.sendToSuiteNumber
      }
      if (values.suiteNumber) {
        obj.suiteNumber = values.suiteNumber
      }

      /* SET LOADING AS TRUE */
      setFormLoading(true);
      /* CALL LOGIN API */
      transcriptForm(obj)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            /* SUCCESS NOTIFICATION */
            toast.success(res?.message);
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formikFormFunction.handleReset();
          setFormLoading(false);
        });
    },
  });

  return (
    <Card className="transcript-box-card">
      <Card.Body className="transcript-box-cardbody">
        <form className="contact-form" onSubmit={formikFormFunction.handleSubmit} id="contactUsForm">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  name="firstName"
                  id="firstName"
                  value={formikFormFunction.values.firstName}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.firstName &&
                  formikFormFunction.touched.firstName && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.firstName}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Middle name"
                  name="middleName"
                  id="middleName"
                  value={formikFormFunction.values.middleName}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.middleName &&
                  formikFormFunction.touched.middleName && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.middleName}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  name="lastName"
                  id="lastName"
                  value={formikFormFunction.values.lastName}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.lastName &&
                  formikFormFunction.touched.lastName && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.lastName}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  id="emailAddress"
                  value={formikFormFunction.values.email}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.email &&
                  formikFormFunction.touched.email && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.email}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <PhoneInput
                  className="phone-control"
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={formikFormFunction.values.phone}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("phone", e)
                  }}
                />
                {formikFormFunction.errors.phone &&
                  formikFormFunction.touched.phone && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.phone}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street Address"
                  name="address"
                  id="streetAddress"
                  value={formikFormFunction.values.address}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.address &&
                  formikFormFunction.touched.address && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.address}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address Line 2"
                  name="suiteNumber"
                  id="addressLine2"
                  value={formikFormFunction.values.suiteNumber}
                  onChange={formikFormFunction.handleChange}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name="city"
                  id="city"
                  value={formikFormFunction.values.city}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.city &&
                  formikFormFunction.touched.city && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.city}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  name="state"
                  id="state"
                  value={formikFormFunction.values.state}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.state &&
                  formikFormFunction.touched.state && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.state}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Zip/Postal Code"
                  name="zipCode"
                  id="postalCode"
                  value={formikFormFunction.values.zipCode}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.zipCode &&
                  formikFormFunction.touched.zipCode && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.zipCode}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <Select options={options} value={formikFormFunction.values.country} onChange={(e) => {
                  formikFormFunction.setFieldValue("country", e)
                }} />
                {formikFormFunction.errors.country &&
                  formikFormFunction.touched.country && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.country}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Student ID Number"
                  name="studentIdNumber"
                  id="studentID"
                  value={formikFormFunction.values.studentIdNumber}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.studentIdNumber &&
                  formikFormFunction.touched.studentIdNumber && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.studentIdNumber}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <p
                className="text"
                style={{ fontWeight: "600", marginBottom: "5px" }}
              >
                By Checking below, I attach my digital signature and agree to
                the following: *
              </p>
              <p className="small-text mt-0">
                I understand that: The Family Educational Rights and Privacy Act
                (FERPA) (20 U.S.C. 1232g; 34 CFR Part 99) is a federal law that
                protects the privacy of student education records. Transcripts
                can only be released to third party with an Authorization to
                Release information Form signed by the student (per
                transaction). I understand that: We do not hold transcripts for
                grades, petitions or graduation. It is my responsibility to
                ensure that all grades are posted, and any petitions approved
                and noted on my record prior to submitting this request.
              </p>
              <div className="checkbox-list">
                <Form.Check
                  type="radio"
                  label="I Agree"
                  name="permission"
                  className="checkbox-control"
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    color: "#848E9F",
                  }}
                />
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group mt-2">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Total Copies Requested"
                  name="totalCopiesRequested"
                  id="copies"
                  value={formikFormFunction.values.totalCopiesRequested}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.totalCopiesRequested &&
                  formikFormFunction.touched.totalCopiesRequested && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.totalCopiesRequested}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Send To"
                  name="sendTo"
                  id="sendTo"
                  value={formikFormFunction.values.sendTo}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.sendTo &&
                  formikFormFunction.touched.sendTo && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendTo}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Attn"
                  name="attn"
                  id="attn"
                  value={formikFormFunction.values.attn}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.attn &&
                  formikFormFunction.touched.attn && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.attn}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <p
                className="text"
                style={{ fontWeight: "600", marginBottom: "5px" }}
              >
                Send To Address
              </p>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street Address"
                  name="sendToAddress"
                  id="streetAddress"
                  value={formikFormFunction.values.sendToAddress}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.sendToAddress &&
                  formikFormFunction.touched.sendToAddress && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendToAddress}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address Line 2"
                  name="sendToSuiteNumber"
                  id="addressLine2"
                  value={formikFormFunction.values.sendToSuiteNumber}
                  onChange={formikFormFunction.handleChange}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name="sendToCity"
                  id="city"
                  value={formikFormFunction.values.sendToCity}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.sendToCity &&
                  formikFormFunction.touched.sendToCity && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendToCity}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  name="sendToState"
                  id="state"
                  value={formikFormFunction.values.sendToState}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.sendToState &&
                  formikFormFunction.touched.sendToState && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendToState}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group mb-0">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Zip/Postal Code"
                  name="sendToZipCode"
                  id="postalCode"
                  value={formikFormFunction.values.sendToZipCode}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.sendToZipCode &&
                  formikFormFunction.touched.sendToZipCode && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendToZipCode}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group mb-0">
                <Select options={options} value={formikFormFunction.values.sendToCountry} onChange={(e) => {
                  formikFormFunction.setFieldValue("sendToCountry", e)
                }} />
                {formikFormFunction.errors.sendToCountry &&
                  formikFormFunction.touched.sendToCountry && (
                    <small style={{ color: "red" }}>
                      {formikFormFunction.errors.sendToCountry}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12">
              {formLoading ? <Button className="action-btn" type="button">Please wait..</Button> : <Button className="action-btn" type="submit">Submit</Button>}

            </div>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};

export default RequestForm;
