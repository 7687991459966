import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from "../config";

/* BOOTSTRAP COMPONENTS */
import { Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';

const Header = () => {
  return (
    <header className="header">
      <div className="container-full-width-padding">
        <div className="header-row">
          {/* LOGO */}
          <Link to="/">
            <div className="logo">
              <img
                src={`${IMAGE_URL}/logos/clement-career-college.webp`}
                alt="Clement Career College"
              />
            </div>
          </Link>

          {/* NAVIGATION LINKS */}
          <Navbar expand="xl" className="navbar">
            <Navbar.Toggle aria-controls="menu-expand" />
            <Navbar.Offcanvas id="menu-expand" placement="start">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-expand-lg">
                  <Link to="/">
                    <div>
                      <img
                        src={`${IMAGE_URL}/logos/clement-career-college.webp`}
                        className="logo"
                        alt="Clement Career College"
                      />
                    </div>
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="main-menu">
                  <Nav.Link href="/" className="menu-link">
                    Home
                  </Nav.Link>
                  <NavDropdown title="Programs" className="dropdown">
                    <NavDropdown.Item href="/nursing-assistant-program">
                      Nursing Assistant Program
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/vocational-nursing-program">
                      Vocational Nursing Program
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/medical-office-administrative-assistant-program">
                      Medical Office Administrative Assistant Program
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/medical-assistant-program">
                      Medical Assistant Program
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="About Us" className="dropdown">
                    <NavDropdown.Item href="/about-us">
                      About Us
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/administration-and-staff">
                      Administration &amp; Staff
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/approvals">
                      Approvals
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/employment-opportunity">
                      Employment Opportunity
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/blogs" className="menu-link">
                    Blogs
                  </Nav.Link>
                  <NavDropdown title="Contact Us" className="dropdown">
                    <NavDropdown.Item href="/contact-us">
                      Contact Us
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/transcript-request-form">
                      Transcript Request Form
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/testimonials" className="menu-link">
                    Testimonials
                  </Nav.Link>
                  <NavDropdown title="Get Started" className="dropdown">
                    <NavDropdown.Item href="/request-information">
                      Request Information
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/apply-online">
                      Apply Online
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/faqs">FAQ's</NavDropdown.Item>
                  </NavDropdown>
                  <a href="tel:+15625792111" className="header-phone-link">
                    (562) 579-2111
                  </a>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        </div>
      </div>
    </header>
  );
}

export default Header;