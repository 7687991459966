import React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../config";

/* ICONS */
import { ArrowRightIcon } from "@heroicons/react/24/outline";

/* USER DEFINED COMPONENTS */
import GetInTouchBox from "../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const AdvisingCounseling = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Advising Counseling | Clement Career College</title>
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/advising-counseling"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Advising Counseling"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">ADVISING COUNSELING</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <div className="border">
                  <p className="text">
                    Students are encouraged to have an open communication with
                    the instructor and student services if they need advising
                    and counseling services. Counseling services are available
                    upon request by student-to-student services.
                  </p>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <div className="sticky-top bottom-section">
                  <Card className="campus-tour-card">
                    <Card.Body className="campus-tour-cardbody">
                      <h5 className="heading">Tour the Campus</h5>
                      <p className="text">
                        Please feel welcome to contact us and visit our
                        beautiful campus in sunny Gardena, California.
                      </p>
                      <Link
                        to="/request-information"
                        style={{ textDecoration: "none" }}
                      >
                        <Button className="action-btn">
                          Schedule A Tour{" "}
                          <ArrowRightIcon className="right-arrow" />
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                  <Card className="apply-online-card">
                    <Card.Body className="apply-online-cardbody">
                      <h5 className="heading">Apply Online Today</h5>
                      <p className="text">
                        Ready to enroll in your future? Apply online today and
                        start your journey with us.
                      </p>
                      <Link
                        to="/apply-online"
                        style={{ textDecoration: "none" }}
                      >
                        <Button className="action-btn">
                          Apply Online{" "}
                          <ArrowRightIcon className="right-arrow" />
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default AdvisingCounseling;
