/* ASSETS */
import { IMAGE_URL } from "../../config";

const testimonials = [
  {
    id: 8,
    image: `${IMAGE_URL}/testimonials/Angelica_G.webp`,
    name: "Angelica G.",
    details:
      "Do yourself a favor and come here!! They care about their students and offer support in everything! I am glad I decided to come here, got my CNA certificate and now working. I'm planning on doing the LVN program. They are so nice and care about their students.",
  },
  {
    id: 9,
    image: `${IMAGE_URL}/testimonials/Dennis_S.webp`,
    name: "Dennis S.",
    details:
      "Reflecting on my time at Clement Career College, I'm filled with gratitude for the incredible experience that has shaped me. From late night study sessions, laughter in the hallways, to the inspiring teacher, classmates who became family, each moment has been a stepping stone in my educational journey. Thank you to all the director and the staff.",
  },
  {
    id: 10,
    image: `${IMAGE_URL}/testimonials/Angie_A.webp`,
    name: "Angie A.",
    details:
      "I was pleased with the staff at this school. They were kind and professional. The instructor was flexible, understanding, and seemed to genuinely care about her students.",
  },
  {
    id: 1,
    image: `${IMAGE_URL}/testimonials/Irene_Kamau.webp`,
    name: "Irene K.",
    details:
      "Clement College is the best college ever that I can recommend to anyone looking to pursue a career in healthcare. The staff are super cool and friendly and I always felt at home at Clement Career College. I am acknowledging all staff, they are the best! If anyone is looking for a good school, look no further and come to Clement Career College.",
  },
  {
    id: 2,
    image: `${IMAGE_URL}/testimonials/Yamill_Dominguez.webp`,
    name: "Yamill D.",
    details:
      "Clement Career College helped me achieve my goal by providing me with the materials and great people to be around with in order to succeed in my nursing path.",
  },
  {
    id: 3,
    image: `${IMAGE_URL}/testimonials/Daniela_Somera.webp`,
    name: "Daniela S.",
    details:
      "Clement Career College gave me the confidence and the knowledge to become a great CNA! Thanks to my teacher for having the patience to explain the skills and theory. And now that I’ve taken my state test, I passed both skills and written. I can now go forward with my career as a CNA! I will definitely come back and get my medical assistant training. The environment here feels like home and I thank them for everything.",
  },
  {
    id: 4,
    image: `${IMAGE_URL}/testimonials/Nancy_R.webp`,
    name: "Nancy R.",
    details:
      "I love Clement Career College! I had an awesome experience. The staff are amazing. The teachers would always help me with my work. I am a certified CNA now thanks to Clement Career College. I want to go back to the LVN program and I definitely recommend this school to anybody who asks me. You won’t regret it.",
  },
  {
    id: 5,
    image: `${IMAGE_URL}/testimonials/Tanya_Perez.webp`,
    name: "Tanya P.",
    details:
      "I just want to share the wonderful experience I had with this school. I graduated as a CNA and passed my state exam. The teacher was very helpful and motivated us to finish. I have two boys and sometimes that was a little bit of a struggle, but they really work with you. I believe that actions speak volumes, and in this case, they really did. I am very grateful to them and I am even coming back next year for the LVN program. Whoever is considering going back to school, this a good place to start and continue for your future. It’s worth the investment.",
  },
  {
    id: 6,
    image: `${IMAGE_URL}/testimonials/Michelle_Layam.webp`,
    name: "Michelle L.",
    details:
      "I enrolled myself at this school with no expectations to actually complete the program. Fast forward to today, I just graduated. It’s sort of a bittersweet moment. I never expected to become a part of a new family. I met strangers that now turned into friends and met our amazing instructor, Nestle who has helped us tirelessly from day one to the last school day. The school gave us an opportunity to grow not just in our career, but personally as well. I gained new experiences and am proud to say, “I’ve become the best version of myself.",
  },
  {
    id: 7,
    image: `${IMAGE_URL}/testimonials/Antonio_Estonactoc.webp`,
    name: "Antonio E.",
    details:
      "Thank you for the knowledge and skills, I’ll definitely use what I learned for future endeavors. And thank you to the amazing teachers, you guys are awesome, God Bless.",
  },
];

export default testimonials;
