import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from "framer-motion";
import { IMAGE_URL } from "../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../components/sidebar";

/* TESTIMONIAL DATA */
import testimonials from "./data";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const Testimonials = () => {
  const TestimonialBox = (props) => {
    return (
      <div className="testimonial-box" key={props.id}>
        <div className="desc">
          <img src={props.image} alt={props.name} />
          <div>
            <p className="text">“{props.details}”</p>
            <p className="name">{props.name}</p>
          </div>
        </div>
        <img
          src={`${IMAGE_URL}/icons/quote-icon.webp`}
          className="quote-icon"
          alt="Testimonials"
        />
      </div>
    );
  };

  return (
    <AnimatePresence>
      <Helmet>
        <title>Testimonials | Clement Career College</title>
        <meta
          name="description"
          content="Getting a healthcare career begins with nursing education programs and a CNA training program offered by Clement Career College."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/testimonials"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Testimonials"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">TESTIMONIALS</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                {testimonials.map((testimonial) => (
                  <TestimonialBox
                    key={testimonial.id}
                    image={testimonial.image}
                    name={testimonial.name}
                    details={testimonial.details}
                  />
                ))}
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Testimonials;
