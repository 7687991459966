import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ModalImage from "react-modal-image";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const MedicalOfficeAdministrativeProgram = () => {
  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="program"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/medical-office-administrative-assistant-banner.webp`}
              alt="Medical Office Administrative Assistant Program"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">
                MEDICAL OFFICE ADMINISTRATIVE ASSISTANT PROGRAM
              </h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="program-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <p className="big-text">
                  At Clement Career College, you will learn the aspects of
                  Medical Office Administration to prepare you on the business
                  side of healthcare, learning the day-to-day operations of the
                  practice supporting roles that provide extensive
                  administrative services in a medical office setting.
                </p>
              </div>
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div>
                  <h2 className="heading">
                    What Does A Medical Office Administrative Do?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Medical office administration encompasses all of the
                      managerial and support roles that provide administrative
                      services in a medical office setting, including records
                      and billing clerks, receptionists, office managers, and
                      administrative assistants. The following article discusses
                      the roles and responsibilities in medical office
                      administration.
                    </p>
                    <p className="text">
                      Typical duties in medical office administration include:
                    </p>
                    <ul className="list">
                      <li>Diagnosis and procedure coding</li>
                      <li>Maintaining records</li>
                      <li>Scheduling appointments and procedures</li>
                      <li>Ordering supplies and equipment</li>
                      <li>Medical transcription</li>
                      <li>Insurance filing and billing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    Where Do Medical Office Administrators Work?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Medical office administrators work in physicians' offices,
                      medical clinics, long-term care facilities, hospitals, and
                      many other healthcare service settings. They are
                      responsible for front office duties, including tasks
                      ranging from answering phones and greeting patients to
                      managing health records and transcribing medical
                      documents. Some of the work requires specialized skills,
                      such as the usage of medical terminology or medical
                      coding.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Are The Admission Requirements ?
                  </h2>
                  <div className="mt-2">
                    <ul className="list mt-0">
                      <li>At least 18 years old</li>
                      <li>Copy Valid California ID and Social Security Card</li>
                      <li>
                        High School Diploma or GED (If USA graduate, HS copy) If
                        foreign graduate, need to complete GLOBAL SERVICES
                        EVALUATION information – available in front desk
                      </li>
                      <li>
                        Must take and pass the Scholastic Level Exam (SLE TEST)
                        prior to registration
                      </li>
                      <li>
                        Physical Examination (form available at the admission
                        department) and TB test done no later than 60 days prior
                        to enrollment; Chest X-ray if history of positive PPD no
                        more than 1 year
                      </li>
                      <li>
                        Immunization records of: COVID-19, Hep B Vaccine, MMR,
                        Varicella, Tetanus
                      </li>
                      <li>
                        Physical Exam, immunization records & TB Test or Chest
                        X-Ray (to be submitted within the 3 rd day of class)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    How Long Does It Take To Complete The Medical Office
                    Administrative Program ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">35 weeks</p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Types Of Courses Are Taught ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Students pursuing a medical office administration
                      certificate gain an understanding of medical terminology,
                      basic bookkeeping principles specific to the medical
                      industry, and medical billing and coding.
                    </p>
                    <ul className="list mt-0">
                      <li>Keyboarding</li>
                      <li>Medical Legal Issues</li>
                      <li>HIPAA Regulations</li>
                      <li>Workplace Ethics</li>
                      <li>Medical Office Terms</li>
                      <li>Management Skills</li>
                      <li>Insurance Billing</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">Where Can I Take My Classes ?</h2>
                  <div className="mt-2">
                    <p className="text">
                      Classes for the Medical Office Administrative Program is
                      taught at our school campus located at 1225 West 190th
                      Street, Suite 375 Gardena, California 90248.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sideabout-image">
                  <ModalImage
                    small={`${IMAGE_URL}/banners/medical-office-administrative-flyer.webp`}
                    large={`${IMAGE_URL}/banners/medical-office-administrative-flyer.webp`}
                    alt="Medical Office Administrative Program"
                  />
                </div>
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default MedicalOfficeAdministrativeProgram;
