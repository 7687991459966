import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";
import { Helmet } from "react-helmet";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const Careers = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Careers | Clement Career College</title>
        <meta
          name="description"
          content="Grow your career as a Nursing Assistant. The Clement Career College offers CNA career opportunities in California. Visit our website for more information. Join us today."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/employment-opportunity"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Employment Opportunity"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">EMPLOYMENT OPPORTUNITY</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div className="border">
                  <p className="text">
                    Thank you for your interest in a position with Clement
                    Career College.
                  </p>
                  <p className="text">
                    We are currently accepting applications for teaching{" "}
                    <b>faculty and administrative staff.</b>
                  </p>
                  <p className="text">
                    CNA Faculty Employment Requirements: (CDPH Requirement for
                    instructor approval)
                  </p>
                  <ul className="list">
                    <li>Two (2) years of work experience as LVN or RN.</li>
                    <li>
                      One (1) year of verifiable experiences as an LVN providing
                      care and services in Acute care hospital, skilled nursing
                      facility, intermediate care facility, home care, hospice
                      care or other long-term care setting.
                    </li>
                    <li>
                      Completion of a course in teaching adults or 1 year of
                      verifiable experience in teaching adults or 1 year of
                      verifiable experience supervising nurse aides.
                    </li>
                  </ul>
                  <div className="bottom-border">
                    <h5 className="heading">
                      For more information, please send an email to{" "}
                      <a href="mailto:aazurin@clementcareercollege.net">
                        aazurin@clementcareercollege.net
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Careers;
