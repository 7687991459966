import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet"; 
import { IMAGE_URL } from "../../config";

/* USER DEFINED COMPONENTS */
import ContactUsCard from "../../components/contact-us";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const ContactUs = () => {
    return (
      <AnimatePresence>
        <Helmet>
          <title>Contact Us | Clement Career College</title>
          <meta
            name="description"
            content="Contact Clement Career College for any query. Get in touch with our team for any Inquiries give us a call at +(562) 579-2111."
          />
          <link
            rel="canonical"
            href="https://www.clementcareercollege.org/contact-us"
          />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        </Helmet>

        <motion.section
          variants={containerAnimation}
          initial="hidden"
          animate="visible"
          className="other-pages"
        >
          {/* BANNER */}
          <motion.div variants={animation} className="banner-with-image">
            <div className="image">
              <img
                src={`${IMAGE_URL}/banners/request-info-banner.webp`}
                alt="Contact Us"
              />
            </div>

            <div className="head">
              <div className="container">
                <h1 className="heading">CONTACT US</h1>
              </div>
            </div>
          </motion.div>

          {/* GET IN TOUCH BOX */}
          <motion.div variants={animation} className="get-in-touch with-b">
            <div className="container">
              <ContactUsCard />
            </div>
          </motion.div>

          {/* INSTRUCTIONS */}
          <motion.div variants={animation} className="instructions">
            <div className="container">
              <div className="side-boxes">
                <div className="box left">
                  <h5 className="heading">Directions From The North:</h5>
                  <div className="directions">
                    <p className="text">Take I-110 S</p>
                    <p className="text">
                      Use the right 3 lanes to take the 190th Street exit
                    </p>
                    <p className="text">Turn right onto W. 190th St</p>
                    <p className="text">
                      Clement Career College will be on your right at the corner
                      of W
                    </p>
                    <p className="text">190th and Normandie Ave.</p>
                  </div>
                </div>
                <div className="box right">
                  <h5 className="heading">Directions From The North:</h5>
                  <div className="directions">
                    <p className="text">Take I-110 S</p>
                    <p className="text">
                      Use the right 3 lanes to take the 190th Street exit
                    </p>
                    <p className="text">Turn right onto W. 190th St</p>
                    <p className="text">
                      Clement Career College will be on your right at the corner
                      of W
                    </p>
                    <p className="text">190th and Normandie Ave.</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.section>
      </AnimatePresence>
    );
};

export default ContactUs;