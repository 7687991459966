/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import { IMAGE_URL } from "../../config";

/* BOOTSTRAP COMPONENTS */
import { Button } from "react-bootstrap";

/* ICONS */
import CircleArrowRight from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook-f";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

/* STYLES */
import "react-phone-number-input/style.css";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* API IMPORTS */
import { getInstagramPosts, landingPageForm } from "../../service/api";

/* CUSTOME COMPONENTS */
import BlogsContainer from "../../components/blogs-container";

/* VALIDATION SCHEMA FOR LOGIN FORM */
const landingPageFormValidation = yup.object().shape({
  name: yup
    .string()
    .trim()
    .strict(true, "Name is required")
    .min(1, "Name is required")
    .required("Name is required."),
  email: yup
    .string()
    .email()
    .trim()
    .strict(true, "Email Address is required")
    .min(1, "Email Address is required")
    .required("Email Address is required."),
  phone: yup
    .string()
    .trim()
    .strict(true, "Phone Number is required")
    .min(1, "Phone Number is required")
    .required("Phone Number is required."),
  programOfInterest: yup.object().required("Program of Interest is required."),
  findUs: yup.object().required("Find Us is required."),
});

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const programOptions = [
  {
    value: "Nursing Assistant Training Program",
    label: "Nursing Assistant Training Program",
  },
  {
    value: "Licensed Vocational Nurse Program",
    label: "Licensed Vocational Nurse Program",
  },
  {
    value: "Medical Office Administrative Program",
    label: "Medical Office Administrative Program",
  },
  {
    value: "Medical Assistant Program",
    label: "Medical Assistant Program",
  },
];

const findUsOptions = [
  {
    value: "Google",
    label: "Google",
  },
  {
    value: "Alumni Student",
    label: "Alumni Student",
  },
];

const Homepage = () => {
  const [instagramWallData, setInstagramWallData] = useState(null);

  const LandingPageFormComponent = () => {
    const [landingFormLoading, setLandingFormLoading] = useState(false);

    /* HANDLE LOGIN FORM USING FORMIK */
    const formikFormFunction = useFormik({
      /* SET INITIAL VALUES FOR LOGIN FORM */
      initialValues: {
        name: "",
        email: "",
        phone: "",
        programOfInterest: "",
        findUs: "",
      },
      /* VALIDATION SCHEMA  */
      validationSchema: landingPageFormValidation,
      /* HANDLE FORM SUBMIT */
      onSubmit: (values) => {
        const obj = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          programOfInterest: values.programOfInterest.value,
          findUs: values.findUs.value,
        };
        /* SET LOADING AS TRUE */
        setLandingFormLoading(true);
        /* CALL LOGIN API */
        landingPageForm(obj)
          .then((res) => {
            /* CHECK RESPONSE STATUS */
            if (res?.status) {
              /* SUCCESS NOTIFICATION */
              toast.success(res?.message);
            } else {
              /* ERROR NOTIFICATION */
              toast.error(res?.message);
            }
            setLandingFormLoading(false);
          })
          .catch((e) => {
            setLandingFormLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            formikFormFunction.handleReset();
            setLandingFormLoading(false);
          });
      },
    });

    return (
      <>
        <form onSubmit={formikFormFunction.handleSubmit} id="landingPageForm">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full name"
                  name="name"
                  id="name"
                  value={formikFormFunction.values.name}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.name &&
                  formikFormFunction.touched.name && (
                    <small style={{ color: "white" }}>
                      {formikFormFunction.errors.name}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="form-group">
                <PhoneInput
                  className="phone-control"
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={formikFormFunction.values.phone}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("phone", e);
                  }}
                />
                {formikFormFunction.errors.phone &&
                  formikFormFunction.touched.phone && (
                    <small style={{ color: "white" }}>
                      {formikFormFunction.errors.phone}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  value={formikFormFunction.values.email}
                  onChange={formikFormFunction.handleChange}
                />
                {formikFormFunction.errors.email &&
                  formikFormFunction.touched.email && (
                    <small style={{ color: "white" }}>
                      {formikFormFunction.errors.email}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <Select
                  options={programOptions}
                  className="react-select"
                  classNamePrefix="select-control"
                  placeholder="Program of interest"
                  value={formikFormFunction.values.programOfInterest}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("programOfInterest", e);
                  }}
                />
                {formikFormFunction.errors.programOfInterest &&
                  formikFormFunction.touched.programOfInterest && (
                    <small style={{ color: "white" }}>
                      {formikFormFunction.errors.programOfInterest}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="form-group">
                <Select
                  options={findUsOptions}
                  className="react-select"
                  classNamePrefix="select-control"
                  placeholder="How did you find us?"
                  value={formikFormFunction.values.findUs}
                  onChange={(e) => {
                    formikFormFunction.setFieldValue("findUs", e);
                  }}
                />
                {formikFormFunction.errors.findUs &&
                  formikFormFunction.touched.findUs && (
                    <small style={{ color: "white" }}>
                      {formikFormFunction.errors.findUs}
                    </small>
                  )}
              </div>
            </div>
          </div>
          <div className="submit-row">
            {landingFormLoading ? (
              <Button className="submit-btn" type="button">
                Please wait..
              </Button>
            ) : (
              <Button type="submit" className="submit-btn">
                Learn more
              </Button>
            )}
          </div>
        </form>
      </>
    );
  };

  /* GET INSTAGRAM POSTS */
  function getInstagramPostsFunction() {
    const obj = {
      postFor: "CLEMENTCAREERCOLLEGE",
      limit: 4,
    };
    getInstagramPosts(obj).then((res) => {
      if (res?.status) {
        setInstagramWallData(res?.data);
      }
    });
  }

  useEffect(() => {
    getInstagramPostsFunction();
  }, []);

  const ProgramBox = (props) => {
    return (
      <Link to={props.navLink}>
        <div className="program-box">
          <img
            src={props.programImg}
            className="banner"
            alt={props.programName}
          />
          <div className="overlay">
            <Link
              to={props.navLink}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Button className="learn-btn">
                <p>{props.programName}</p>
                <CircleArrowRight color="#323232" size="22" />
              </Button>
            </Link>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="homepage"
      >
        {/* VIDEO BANNER */}
        <motion.div variants={animation} className="video-banner">
          <video autoPlay muted loop>
            <source src={`${IMAGE_URL}/banner-video.mp4`} type="video/mp4" />
          </video>
        </motion.div>

        {/* TAGLINE */}
        <motion.div variants={animation} className="tagline">
          <div className="container">
            <h1 className="heading">
              "Launch Your Healthcare Career Today And Train Professionally To
              Become A Successful Healthcare Hero"
            </h1>
          </div>
        </motion.div>

        {/* PROGRAMS BANNER */}
        <motion.div variants={animation} className="programs-banner">
          <div className="container-full-width">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-12 px-0">
                <ProgramBox
                  programName="Nursing Assistant Program"
                  programImg={`${IMAGE_URL}/banners/nursing-assistant-training-program-banner.webp`}
                  navLink="/nursing-assistant-program"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12 px-0">
                <ProgramBox
                  programName="Vocational Nursing Program"
                  programImg={`${IMAGE_URL}/banners/licensed-vocational-nurse-program-banner.webp`}
                  navLink="/vocational-nursing-program"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12 px-0">
                <ProgramBox
                  programName="Medical Office Administrative Assistant Program"
                  programImg={`${IMAGE_URL}/banners/medical-office-administrative-program-banner.webp`}
                  navLink="/medical-office-administrative-assistant-program"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12 px-0">
                <ProgramBox
                  programName="Medical Assistant Program"
                  programImg={`${IMAGE_URL}/banners/medical-assistant-program-banner.webp`}
                  navLink="/medical-assistant-program"
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* CONTACT FORM */}
        <motion.div variants={animation} className="contact-form">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-5 col-lg-5 col-sm-12">
                <h1 className="heading">
                  Want to learn more about Clement Career College? Please fill
                  out the form:
                </h1>
                <p className="text">
                  By filling out the form, I consent to receive calls, emails
                  and/or text messages by a college representative with
                  information for educational opportunities at the number(s)
                  provided above. I understand calls may be initiated by an
                  automated telephone dialing system.
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <LandingPageFormComponent />
              </div>
            </div>
          </div>
        </motion.div>

        {/* ABOUT CLEMENT CAREER COLLEGE */}
        <motion.div variants={animation} className="about-college">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 col-sm-12">
                <img
                  src={`${IMAGE_URL}/banners/about-college-banner.webp`}
                  className="banner"
                  alt="About Clement Career College"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <h2 className="heading">
                  Obtain Quality Education from one of the best nursing colleges
                  in California
                </h2>
                <p className="text">
                  Clement Career College is proud to welcome and congratulate
                  you on taking the first step towards your personal and
                  professional goal of becoming a successful healthcare worker.
                  Our institution has a reputation for providing quality
                  healthcare education to students, and we are excited to have
                  you join our community of aspiring healthcare heros.
                </p>
                <p className="text">
                  With hands-on training and real world experience, you will
                  gain the skills necessary to become a competent healthcare
                  worker. Our caring and professional faculty and staff will
                  guide you throughout your journey, ensuring that you receive
                  the very best education and support to help you achieve your
                  educational and career goals.
                </p>
                <div className="social-media">
                  <div className="social-links">
                    <a
                      href="https://www.facebook.com/ClementCareerCollegeOfficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <FacebookIcon color="#213360" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://www.instagram.com/clementcareercollege/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <InstagramIcon color="#213360" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://twitter.com/ClementCareers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <TwitterIcon color="#213360" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/clementcareercollege/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <LinkedinIcon color="#213360" size="18" />
                      </Button>
                    </a>
                  </div>
                  <Link to="/about-us" className="learn-more-link">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* WHY CLEMENT CAREER COLLEGE */}
        <motion.div variants={animation} className="why-clement-college">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <h1 className="heading">
                  Why choose Clement Career College for your career training?
                </h1>
                <p className="text">
                  We are proud to announce our continued commitment to provide
                  exceptional training programs in the field of CNA, MA, LVN and
                  MOA. Our goal is to ensure that candidates receive the highest
                  quality education and are prepared for successful careers in
                  specialized technical and non-technical fields, with a strong
                  emphasis on allied healthcare careers. We believe in providing
                  our students with the knowledge and skills necessary to excel
                  in their chosen profession, and we are dedicated to helping
                  them achieve their goals. Our team of experienced instructors
                  and staff are committed to providing a supportive and engaging
                  learning environment, and we are constantly improving our
                  program to ensure that our students receive the best education
                  possible.
                </p>
              </div>
            </div>
            <div className="row benefits">
              <div className="col-xl-3 col-lg-3 col-sm-12">
                <div className="icon">
                  <img
                    src={`${IMAGE_URL}/icons/intensive-real-world-training.webp`}
                    alt="Intensive Real World Training"
                  />
                </div>
                <div className="description">
                  <h5 className="heading">Intensive real-world training</h5>
                  <p className="text">
                    Get trained on our campus to become one of the best in the
                    field of healthcare
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12">
                <div className="icon">
                  <img
                    src={`${IMAGE_URL}/icons/experience-faculty.webp`}
                    alt="Experienced Faculty"
                  />
                </div>
                <div className="description">
                  <h5 className="heading">Experienced Faculty</h5>
                  <p className="text">
                    Our world-class faculty are unparalleled when it comes to
                    training our students professionally.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12">
                <div className="icon">
                  <img
                    src={`${IMAGE_URL}/icons/career-placement-assistance.webp`}
                    alt="Career placement assistance"
                  />
                </div>
                <div className="description">
                  <h5 className="heading">Career placement assistance</h5>
                  <p className="text">
                    We assist and prepare you to become one of the best,
                    quality, employees out there once you complete graduation.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-12">
                <div className="icon">
                  <img
                    src={`${IMAGE_URL}/icons/vision.webp`}
                    alt="Our vision that keeps up our pride"
                  />
                </div>
                <div className="description">
                  <h5 className="heading">
                    Our vision that keeps up our pride
                  </h5>
                  <p className="text">
                    We continue on long-standing traditions and rich history of
                    being a college that provides quality healthcare education
                    in California.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* ABOUT US & ADMINISTRATION */}
        <motion.div variants={animation} className="about-boxes">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <div className="box">
                  <img
                    src={`${IMAGE_URL}/banners/about-us.webp`}
                    className="banner"
                    alt="About Us"
                  />
                  <div className="description">
                    <h5 className="heading">About Us</h5>
                    <p className="text">
                      We are committed to providing our students with the tools
                      and resources they need to achieve their goals. Our
                      mission is to guide individuals on a pathway to success by
                      offering quality education and training programs that are
                      designed to prepare them for in-demand careers.
                    </p>
                    <Link to="/about-us" style={{ textDecoration: "none" }}>
                      <Button className="primary-btn">
                        About Us <ArrowRightIcon />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-sm-12">
                <div className="box">
                  <img
                    src={`${IMAGE_URL}/banners/administration-and-staff.webp`}
                    className="banner"
                    alt="Administration & Staff"
                  />
                  <div className="description">
                    <h5 className="heading">Administration &amp; Staff</h5>
                    <p className="text">
                      We understand the importance of providing our students
                      with the necessary tools and support to achieve their
                      educational and career goals. Our faculty and staff are
                      dedicated to ensuring that every student receives the
                      highest quality education and training.
                    </p>
                    <Link
                      to="/administration-and-staff"
                      style={{ textDecoration: "none" }}
                    >
                      <Button className="primary-btn">
                        Administration &amp; Staff <ArrowRightIcon />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* BLOGS */}
        <motion.div variants={animation} className="blogs">
          <div className="container">
            <BlogsContainer />
          </div>
        </motion.div>

        {/* INSTAGRAM WALL */}
        <motion.div variants={animation} className="instagram-wall">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className="instagram-heading-box">
                  <h3>Follow us on Instagram</h3>
                  <a href="https://www.instagram.com/clementcareercollege/">
                    @clementcareercollege
                  </a>
                </div>
              </div>
              {instagramWallData?.length > 0
                ? instagramWallData?.map((data) => {
                    return (
                      <>
                        <div className="col-xl-3 col-lg-4 col-sm-12">
                          <a
                            href={data?.permalink}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={`Instagram caption ${data?.caption}`}
                            className={`instagram-link`}
                          >
                            <div className="instagram-post">
                              {data?.media_type === "IMAGE" ||
                              data?.media_type === "CAROUSEL_ALBUM" ? (
                                <img
                                  src={data?.media_url}
                                  alt="Follow Amberwood Real Estate on Instagram"
                                />
                              ) : (
                                <video
                                  autoPlay
                                  muted
                                  loop
                                  width={"100%"}
                                  style={{ objectFit: "cover" }}
                                >
                                  <source
                                    src={data?.media_url}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                            </div>
                            <div className="bg-overlay">
                              <InstagramIcon color="#FFF" size="30" />
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })
                : false}
            </div>
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Homepage;
