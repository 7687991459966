import React from "react";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const Approvals = () => {
  return (
    <AnimatePresence>
      <Helmet>
        <title>Approvals | Clement Career College</title>
        <meta
          name="description"
          content="Clement Career College’s approval to operate in the State of California is based on provisions of California Education. Nursing Assistant program is approved by the CDPH."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/approvals"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Approvals"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">APPROVALS</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div variants={animation} className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div className="border">
                  <p className="text">
                    Clement Career College’s approval to operate in the State of
                    California is based on provisions of the California
                    Education Code Article 4. Exemptions 994874-94874.9 in which
                    an institution is exempt as defined in Section 94830.
                  </p>
                  <p className="text">
                    Clement Career College’s Programs are approved by CDPH, BPPE
                    and BVNPT.
                  </p>
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                      <div className="approval-box">
                        <div className="accred-logo">
                          <img
                            src={`${IMAGE_URL}/logos/california-department-of-public-health.webp`}
                            alt="California Department of Public Health"
                          />
                        </div>
                        <h5 className="heading">
                          California Department of Public Health
                        </h5>
                        <p className="text">PO Box 997377, MS 0500</p>
                        <p className="text">Sacramento, CA 95899-7377</p>
                        <p className="text">
                          Tel: <a href="tel:+19165581784">(916) 558-1784</a>
                        </p>
                        <p className="text">
                          Website:{" "}
                          <a
                            href="https://www.cdph.ca.gov"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://www.cdph.ca.gov
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                      <div className="approval-box">
                        <div className="accred-logo">
                          <img
                            src={`${IMAGE_URL}/logos/accreditation-BPPE.webp`}
                            alt="Bureau for Private Postsecondary Education"
                          />
                        </div>
                        <h5 className="heading">
                          Bureau for Private Postsecondary Education
                        </h5>
                        <p className="text">
                          1747 North Market Blvd., Suite 225
                        </p>
                        <p className="text">Sacramento, CA 95834</p>
                        <p className="text">
                          Tel: <a href="tel:+19165748900">(916) 574-8900</a>
                        </p>
                        <p className="text">
                          Website:{" "}
                          <a
                            href="https://www.bppe.ca.gov"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://www.bppe.ca.gov
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                      <div className="approval-box">
                        <div className="accred-logo">
                          <img
                            src={`${IMAGE_URL}/logos/accreditation-BVNPT.webp`}
                            alt="Board of Vocational Nursing and Pyschiatric Technicians"
                          />
                        </div>
                        <h5 className="heading">
                          Board of Vocational Nursing and Pyschiatric
                          Technicians
                        </h5>
                        <p className="text">
                          2535 Capital Oaks Drive, Suite 205
                        </p>
                        <p className="text">Sacramento, CA 95833</p>
                        <p className="text">
                          Tel: <a href="tel:+19162637800">(916) 263-7800</a>
                        </p>
                        <p className="text">
                          Website:{" "}
                          <a
                            href="https://www.bvnpt.ca.gov"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://www.bvnpt.ca.gov
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default Approvals;
