import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import RequestForm from "../../../components/request-form";
import PageSidebar from "../../../components/sidebar";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const TranscriptRequestForm = () => {
    return (
      <AnimatePresence>
        <Helmet>
          <title>Transcript Request Form | Clement Career College</title>
          <link
            rel="canonical"
            href="https://www.clementcareercollege.org/transcript-request-form"
          />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        </Helmet>

        <motion.section
          variants={containerAnimation}
          initial="hidden"
          animate="visible"
          className="other-pages"
        >
          {/* BANNER */}
          <motion.div variants={animation} className="banner-with-image">
            <div className="image">
              <img
                src={`${IMAGE_URL}/banners/request-info-banner.webp`}
                alt="Transcript Request Form"
              />
            </div>

            <div className="head">
              <div className="container">
                <h1 className="heading">TRANSCRIPT REQUEST FORM</h1>
              </div>
            </div>
          </motion.div>

          {/* DESCRIPTION */}
          <motion.div variants={animation} className="page-details">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-sm-12">
                  <p className="text mb-1">
                    All financial obligation within Clement Career College must
                    be cleared before submitting this request.
                  </p>
                  <p className="text mb-1">
                    These obligations include past, current and future
                    enrollment.
                  </p>
                  <p className="text mb-1">
                    <b>Rush</b> ($10.00): 3 Business Days (For records after
                    2021)
                  </p>
                  <p className="text mb-1">
                    <b>Same Day</b> ($10.00): Presented in person (For records
                    after 2021)
                  </p>
                  <p className="text mb-1">
                    <b>Regular</b> ($3.00): 10 business days
                  </p>

                  {/* REQUEST FORM */}
                  <RequestForm />
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12">
                  <PageSidebar />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.section>
      </AnimatePresence>
    );
};

export default TranscriptRequestForm;