const SERVER_URL = "https://clementcareercollege-backend-nodejs-kdtagrlkqq-uc.a.run.app/"
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw";
const INSTAGRAM_KEY = "IGQVJVZA0lwX2wxUVEtbXlQNGVXSnY1STVvbmNiaENYRXVkLVNYY3pSSVRUdFE0eU5WTkRXdFlzQWREMndDTDgtWjktb1B2V3dNbGFka1E1bEFJNnRhRDB6RXVpY3ZAaQUN6dXRmV21kMGNTRFRoYVNkVgZDZD";
const IMAGE_URL = "https://storage.googleapis.com/clement-career-college"; 

  module.exports = {
    SERVER_URL,
    YOUR_GOOGLE_MAPS_API_KEY,
    INSTAGRAM_KEY,
    IMAGE_URL,
  };
