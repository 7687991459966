import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ModalImage from "react-modal-image";
import { IMAGE_URL } from "../../../config";

/* USER DEFINED COMPONENTS */
import PageSidebar from "../../../components/sidebar";
import GetInTouchBox from "../../../components/get-in-touch";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const MedicalAssistantProgram = () => {
  return (
    <AnimatePresence>
      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="program"
      >
        {/* BANNER */}
        <motion.div className="banner">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/medical-assistant-banner.webp`}
              alt="Medical Assistant Program"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">MEDICAL ASSISTANT PROGRAM</h1>
            </div>
          </div>
        </motion.div>

        {/* DESCRIPTION */}
        <motion.div className="program-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <p className="big-text">
                  The Medical Assistant Program prepares students with basic
                  knowledge and skills to be competent and qualified for
                  entry-level position in the medical field. Our program
                  demonstrates knowledge in administrative duties and business
                  management operators as well as various clinical skills.
                </p>
              </div>
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div>
                  <h2 className="heading">
                    What Does A Medical Assistant Do ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Medical Assistants demonstrate knowledge in administrative
                      duties such as filing, appointment scheduling, insurance
                      billing and coding, business management operation of
                      medical office, ethical and legal regulations of
                      healthcare system; and perform various clinical skills
                      such as preparing patients for physical examinations,
                      vital signs, electrocardiogram, venipuncture procedures,
                      and perform waiver laboratory test such as urinalysis,
                      pregnancy test, hematocrit and assist physician with minor
                      surgical procedures.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    Where Do Medical Assistants Work ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      Medical assistants commonly work in physicians’ offices,
                      hospitals, outpatient clinics, and other healthcare
                      facilities. On occasion, they can work for urgent care
                      facilities, palliative care, diagnostic labs, and college
                      and universities.
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Are The Admission Requirements ?
                  </h2>
                  <div className="mt-2">
                    <ul className="list mt-0">
                      <li>At least 18 years old</li>
                      <li>Copy Valid California ID and Social Security Card</li>
                      <li>
                        High School Diploma or GED (If USA graduate, HS copy) If
                        foreign graduate, need to complete GLOBAL SERVICES
                        EVALUATION information – available in front desk
                      </li>
                      <li>
                        Must take and pass the Scholastic Level Exam (SLE TEST)
                        prior to registration
                      </li>
                      <li>
                        Physical Examination (form available at the admission
                        department) and TB test done no later than 60 days prior
                        to enrollment; Chest X-ray if history of positive PPD no
                        more than 1 year
                      </li>
                      <li>
                        Immunization records of: COVID-19, Hep B Vaccine, MMR,
                        Varicella, Tetanus
                      </li>
                      <li>
                        Physical Exam, immunization records & TB Test or Chest
                        X-Ray (to be submitted within the 3 rd day of class)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    How Long Does It Take To Complete The Medical Office
                    Administrative Program ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">28 weeks</p>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">
                    What Types Of Courses Are Taught ?
                  </h2>
                  <div className="mt-2">
                    <p className="text">
                      The medical assistant program is composed of theoretical,
                      skills, and clinical components. Lectures and skills
                      components are delivered in the main classroom and skills
                      laboratory respectively for all courses in the program.
                      The courses are:
                    </p>
                    <ul className="list mt-0">
                      <li>Introduction to Medical Assisting</li>
                      <li>Medical Office Business Management</li>
                      <li>Anatomy & Physiology</li>
                      <li>Medical Office Clinical Procedure I</li>
                      <li>Medical Office Clinical Procedure II</li>
                      <li>Externship</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <h2 className="heading">Where Can I Take My Classes ?</h2>
                  <div className="mt-2">
                    <p className="text">
                      Classes for the Medical Assistant Program is taught at our
                      school campus located at 1225 West 190th Street, Suite 375
                      Gardena, California 90248.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sideabout-image">
                  <ModalImage
                    small={`${IMAGE_URL}/banners/medical-assistant-flyer.webp`}
                    large={`${IMAGE_URL}/banners/medical-assistant-flyer.webp`}
                    alt="Medical Assitant Program"
                  />
                </div>
                <PageSidebar />
              </div>
            </div>
          </div>
        </motion.div>

        {/* GET IN TOUCH BOX */}
        <motion.div variants={animation} className="get-in-touch">
          <div className="container">
            <GetInTouchBox />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence>
  );
};

export default MedicalAssistantProgram;
