/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { Helmet } from "react-helmet";

import ReactHtmlParser from 'react-html-parser';

import ReactPaginate from "react-paginate";

import Skeleton from "react-loading-skeleton";

/* IMAGES */
import BlogPlaceholder from '../../assets/blog-placeholder.png';

/* ICONS */
import PreviousIcon from '@iconscout/react-unicons/icons/uil-previous';
import CloseIcon from "@iconscout/react-unicons/icons/uil-times";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* APIS */
import { getBlogs, getBlogCategories } from "../../service/api";

/* REDUX REQUIREMENTS */
import actions from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

/* LOADING SCREEN */
import { Button } from "react-bootstrap";

/* CONFIG */
import { IMAGE_URL } from "../../config";

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const BlogBoxLoading = () => {
  return (
    <div className="blog-box new-one">
      <img
        src={BlogPlaceholder}
        alt='Blog'
      />
      <div className="details">
        <Skeleton className="loading-skeleton" />
        <Skeleton className="title-loading-skeleton" />
      </div>
      <div className="time-to-read">
        <Skeleton className="time-loading-skeleton" />
      </div>
    </div>
  );
};

const BlogBox = (props) => {
  return <Link to={"/blog-detail/" + props?.blogDetails?._id}>
    <div className="blog-box">
      <img
        src={IMAGE_URL + "/" + props.imgLink}
        alt={props.blogTitle}
      />
      <div className="details">
        <h5 className="heading">
          {props.blogTitle}
        </h5>

        <p className="truncate-text" >
          {ReactHtmlParser(props?.blogDetails?.description)}
        </p>
      </div>
    </div>
  </Link>


}
const Blogs = () => {

  /* GET USER STATE REDUX VARIABLE VALUES */
  const blogCategory = useSelector((state) => state?.user?.blogCategory);

  // Initial Functions
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(8);

  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0)

  const [blogsData, setBlogsData] = useState([])
  const [blogsCategoryList, setBlogsCategoryList] = useState([])

  const [category, setCategory] = useState(blogCategory?.categoryId ? blogCategory?.categoryId : null)
  const [categoryName, setCategoryName] = useState(blogCategory?.categoryName ? blogCategory?.categoryName : null)

  useEffect(() => {
    getBlogsListFunction()
  }, [page, sizePerPage, category]);

  /* GET BLOGS LISTING */
  const getBlogsListFunction = () => {
    setLoading(true);

    const obj = {};
    obj.page = page;
    obj.sizePerPage = sizePerPage;
    if (category) {
      obj.category = category;
    }

    getBlogs(obj).then((res) => {
      if (res?.status) {
        setBlogsData(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs)
        setTotalPages(res?.data?.totalPages)
        // setT(res?.data?.totalDocs)
      }
      setLoading(false)
    })
      .catch(err => {
        console.log("err", err)
      })
      .finally(err => {
        setLoading(false)
      })
  }

  const getBlogsCategoryListFunction = () => {
    getBlogCategories().then((res) => {
      if (res?.status) {
        setBlogsCategoryList(res?.data);
      }
    })
      .catch(err => {
        console.log("err", err)
      })
  }

  /* PAGE CLICK */
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setLoading(true)
    setBlogsData([])
    setPage(selectedPage);
    console.log(`Page ${selectedPage} clicked.`);
  };

  const handleClear = () => {
    setCategory(null)
    setCategoryName(null)
    dispatch(actions.setBlogDetails(null))
  }

  return (
    <AnimatePresence>
      <Helmet>
        <title>Blogs | Clement Career College</title>
        <meta
          name="description"
          content="The blog covers all aspects of certified nursing assistant programs. Stay up-to-date with our latest blog. Read more."
        />
        <link
          rel="canonical"
          href="https://www.clementcareercollege.org/blogs"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <motion.section
        variants={containerAnimation}
        initial="hidden"
        animate="visible"
        className="other-pages"
      >
        {/* BANNER */}
        <motion.div variants={animation} className="banner-with-image">
          <div className="image">
            <img
              src={`${IMAGE_URL}/banners/request-info-banner.webp`}
              alt="Blogs"
            />
          </div>

          <div className="head">
            <div className="container">
              <h1 className="heading">LATEST BLOGS</h1>
            </div>
          </div>
        </motion.div>

        {/* BLOGS LIST */}
        <motion.div variants={animation} className="blogs-list">
          <div className="container">
            {/* FILTERS APPLIED BOX */}
            {category ? (
              <div className="filters-applied">
                <p>Filters:</p>
                <div className="applied-filters-list">
                  <div className="applied-filter">
                    <p>
                      <b style={{ fontWeight: "600" }}>
                        {categoryName}
                      </b>
                    </p>
                    <Button className="close-btn" onClick={handleClear}>
                      <CloseIcon size="14" color="#3b4f76" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row gy-4">

              {loading && (
                <>
                  <div className="col-xl-4 col-sm-12">
                    <BlogBoxLoading />
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <BlogBoxLoading />
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <BlogBoxLoading />
                  </div>
                </>
              )}

              {blogsData?.length > 0 && (blogsData.map((blog) => {
                return (
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <BlogBox
                      imgLink={blog?.image}
                      blogTitle={blog?.name}
                      date={blog?.startDate}
                      blogDetails={blog}
                    />
                  </div>
                );
              }))}

            </div>

            <ReactPaginate
              className="blogs-pagination"
              previousLabel={
                <Button className="blog-pagination-btn">
                  <PreviousIcon color="#323232" size="20" />
                </Button>
              }
              nextLabel={
                <Button className="blog-pagination-btn">
                  <NextIcon color="#323232" size="20" />
                </Button>
              }
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={3}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              activeClassName={"active"}
            />
          </div>
        </motion.div>
      </motion.section>
    </AnimatePresence >
  );
};

export default Blogs;